import React, {useEffect} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import helper from "../../constants/helper";
import {routes} from "../../routes/routes";

const PrivateOutlet = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem(helper.EPIC_JWT);
    const profileID = localStorage.getItem(helper.EPIC_PROFILE_ID);
    // For adult page
    useEffect(() => {
        return () => {
            sessionStorage.setItem("adultsPageValidated", "false");
        };
    }, [location.pathname]);

    useEffect(() => {
        if (token && profileID) return
        if (token && !profileID) {
            navigate(routes.profile); // Redirect to profile creation if no profileID
        } else if (token && profileID) {
            navigate(routes.home);
        }
    }, [token, profileID]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top
    }, [location.pathname]);


    if (!token) {
        return <Navigate to={routes.login}/>;
    }

    // If token exists, render private routes
    return <Outlet/>
};

export default PrivateOutlet;
