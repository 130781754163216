import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchCategoriesRequest} from "../../services/categoriesApi";
import {Category} from "../../types/categories";

interface CategoriesState {
    isFetching: boolean;
    isFetched: boolean;
    list: Array<Category>;
}

const initialState: CategoriesState = {
    isFetching: false,
    isFetched: false,
    list: [],
};

export const fetchCategories = createAsyncThunk(
    "home/fetchCategories",
    async ({page, type}: { page: string; type?: string | undefined }) => {
        return await fetchCategoriesRequest(page, type);
    }
);

const categorySlice = createSlice({
    name: "categories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.isFetching = true;
                state.list = [];
            })
            .addCase(fetchCategories.fulfilled, (state, action: PayloadAction<Array<Category>>) => {
                state.list = action.payload;
                state.isFetching = false;
                state.isFetched = true;
            })
            .addCase(fetchCategories.rejected, (state) => {
                state.isFetching = false;
                state.list = [];
            })
    },
});

export default categorySlice.reducer;




