import React, {useEffect, useState, MouseEvent} from "react";
import {
    Button,
    CustomModal,
    ProfileAdult,
    ProfileImage,
    ProfileLanguage,
    ProfileName,
} from "../../components";
import styles from "./Settings.module.css";
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import Avatar from "../../assets/images/Avatar.png";
import {fetchUserProfile} from "../../store/profile/thunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../routes/routes";
import {deleteProfile, forceLogout} from "../../services/userAPI";
import {SmileIcon} from "../../assets/files";
import {useSettingsData, useSettingsDataChildren} from "../ProfileEdit/data";
import Keys from "../../constants/helper";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

// Define Settings Component
const Settings = () => {
    const {innerWidth} = useResize()
    const {t} = useTranslation("translations");
    const profileId = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const role = localStorage.getItem(Keys.EPIC_USER_ROLE);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const {value} = location.state || {};
    const settingsData = useSettingsData()
    const settingsDataChildren = useSettingsDataChildren()
    const {profile} = useSelector((state: RootState) => state.profile);
    const [selectedIndex, setSelectedIndex] = useState<number>(1);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);

    useEffect(() => {
        innerWidth < 860 && setSelectedIndex(2)
    }, [innerWidth]);

    // Fetch user profile on component mount
    useEffect(() => {
        if (profileId) {
            dispatch(fetchUserProfile(profileId));
        }
    }, [profileId]);

    // Handle navigation within settings menu
    const handleListItemClick = (event: MouseEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();

        if (index === 6) {
            forceLogout();
        } else if (index === 5) {
            setOpenModal(true);
        } else {
            setSelectedIndex(index);
            setReset(!reset);
        }
    };

    // Handle profile deletion
    const handleDelete = async () => {
        if (profileId) {
            try {
                await deleteProfile(profileId);
                navigate(routes.profile);
                localStorage.removeItem(Keys.EPIC_PROFILE_ID);
                localStorage.removeItem(Keys.EPIC_USER_DATA);
            } catch (err) {
                console.error("Error deleting profile:", err);
            }
        }
    };

    // Render selected page based on selected index
    const getPage = () => {
        if (!profileId && !value) return null;

        switch (selectedIndex) {
            case 1:
                return <ProfileImage id={profileId}/>;
            case 2:
                return <ProfileName name={profile?.name} id={profileId}/>;
            case 3:
                return <ProfileLanguage language={profile?.language} id={profileId}/>;
            case 4:
                return <ProfileAdult/>;
            default:
                return null;
        }
    };
    const getKidsPage = () => {
        if (!profileId && !value) return null;
        switch (selectedIndex) {
            case 1:
                return <ProfileImage id={profileId}/>;
            case 2:
                return <ProfileLanguage language={profile?.language} id={profileId}/>;
            default:
                return null;
        }
    };

    const roleSettings = role === "KIDS" ? settingsDataChildren : settingsData

    const filteredSettingsData = (role === "KIDS" || selectedIndex === 0) ? roleSettings : roleSettings.slice(0, -1);

    return (
        <div className={styles.root}>
            {innerWidth > 860 &&
              <Typography variant="h4" className={styles.title}>
                  {t("Authorization.editProfiles")}
              </Typography>}
            <Stack
                direction={innerWidth > 860 ? "row" : "column"}
                gap={innerWidth > 860 ? 8 : 2}
                mt={8}
                height="100%"
            >
                <Box className={clsx(styles.leftBlock, innerWidth <= 860 && styles.mobileLeftBlock)}>
                    {innerWidth > 860 &&
                    <List className={clsx(styles.list, selectedIndex === 0 && styles.listSettings)}>
                        {filteredSettingsData.map((item) => (
                            <ListItemButton
                                key={item.key}
                                onClick={(event) => handleListItemClick(event, item.key)}
                                selected={selectedIndex === item.key}>
                                <ListItemIcon>
                                    <item.icon/>
                                </ListItemIcon>
                                <ListItemText primary={item.name} className={styles.name}/>
                            </ListItemButton>
                        ))}
                    </List>}

                    {selectedIndex !== 0 && (
                        <div className={clsx(styles.profileCard, role === "KIDS" && styles.profileCardKids)}>
                            {profileId ? (
                                <img src={profile?.avatar?.file || Avatar} alt="Avatar" loading={"lazy"}/>
                            ) : (
                                <div className={styles.kids}>
                                    <SmileIcon/>
                                </div>
                            )}
                            <Stack direction="column" alignItems="center" gap={1} className={styles.card}>
                                <Typography variant={innerWidth > 860 ? "h2" : "h5"}>
                                    {profileId ? profile?.name : <>{t("Details.kids")}</>}
                                </Typography>
                                <Typography variant="h5" textTransform="capitalize">
                                    {profile?.language}
                                </Typography>
                            </Stack>
                        </div>
                    )}

                </Box>

                <Box width="100%">
                    {role === "KIDS" ? getKidsPage() : getPage()}
                </Box>
                {innerWidth < 860 &&
                  <List className={clsx(styles.list, selectedIndex === 0 && styles.listSettings)}>
                      {filteredSettingsData.map((item) => (
                          <ListItemButton
                              key={item.key}
                              onClick={(event) => handleListItemClick(event, item.key)}
                              selected={selectedIndex === item.key}>
                              <ListItemIcon>
                                  <item.icon/>
                              </ListItemIcon>
                              <ListItemText primary={item.name} className={styles.name}/>
                          </ListItemButton>
                      ))}
                  </List>}
            </Stack>
            {/* Modal for profile deletion */}
            <CustomModal open={openModal}>
                <Box display="grid" justifyItems="center" gap={2}>
                    <Typography variant="h1">{t("Buttons.deleteProfile")}</Typography>
                    <Typography variant="subtitle2" textAlign="center">{t("Settings.confirmDeleteProfile")}</Typography>
                    <Stack direction="column" gap={2} width="100%" mt={2}>
                        <Button color="primary" variant="contained" fullWidth onClick={handleDelete}>
                            {t("Buttons.deleteProfile")}
                        </Button>
                        <Button fullWidth color="secondary" variant="contained" onClick={() => setOpenModal(false)}>
                            {t("Buttons.cancel")}
                        </Button>
                    </Stack>
                </Box>
            </CustomModal>
        </div>
    );
};

export default Settings;
