import {useEffect, useState} from "react";

export default function useResize() {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    function handleSetSize() {
        setInnerWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", handleSetSize);
        return () => window.removeEventListener("resize", handleSetSize);
    }, []);

    return {innerWidth};
}




