import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IRelated} from "../../components/Cards/types";

interface ActiveTVState {
    activeTV: IRelated | null;
    listTV: IRelated | null;
}

const initialState: ActiveTVState = {
    activeTV: null,
    listTV: null
};

const activeTVSlice = createSlice({
    name: "activeTV",
    initialState,
    reducers: {
        setActiveTV: (state, action: PayloadAction<IRelated>) => {
            state.activeTV = action.payload;
        },
        setListTV: (state, action: PayloadAction<IRelated>) => {
            state.listTV = action.payload;
        },
    },
});

export const {setActiveTV, setListTV} = activeTVSlice.actions;

export default activeTVSlice.reducer;
