import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import styles from "./Search.module.css"
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchSearchPage} from "../../store/contents/thunks";
import {CustomInput, Loader, MovieCard} from "../../components";
import {useSearchParams} from "react-router-dom";
import {EmptyLogo} from "../../assets/files";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

const Search = () => {
    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const {related, isFetching} = useSelector((state: RootState) => state.contents);
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("query") || "";
    const page = searchParams.get("page") || "1";

    useEffect(() => {
        dispatch(fetchSearchPage({query, page}));
    }, [dispatch, query, page]);


    return (
        <Box className={styles.root}>
            <div className={styles.content}>
                {innerWidth > 860 && <Typography variant="h4" className={styles.title}>{t("Aside.search")}</Typography>}
            </div>
            <div className={styles.search}>
                <CustomInput
                    startIcon
                    name="search"
                    placeholder={t("Aside.search")}
                    value={query}
                    onChange={(e) => setSearchParams({page: "1", query: e.target.value})}
                />
            </div>
            {isFetching ? <Loader className={styles.loader}/> :
                <Box className={styles.content_inner}>
                    {related?.map((e, i) => (
                        <div className={styles.card} key={i}>
                            <MovieCard data={e}/>
                        </div>
                    ))}
                </Box>}
            {!isFetching && !related?.length && <div className={styles.loader}>
              <EmptyLogo/>
              <Typography variant={"subtitle2"} maxWidth={300}>{t("Details.notFindAnythingMatching")}</Typography>
            </div>}

        </Box>
    );
};

export default Search;



