import React, {useEffect} from "react";
import styles from "./Serials.module.css";
import {FilmDescription, Loader, FilmLists} from "../../components";
import {Box, Typography} from "@mui/material";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

const Serials = () => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const dispatch: AppDispatch = useDispatch();
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);
    const pageName = "SERIE"

    useEffect(() => {
        dispatch(fetchPage(pageName));
    }, [dispatch]);

    const slicedData = dataPage ? dataPage[0]?.contents[0] : null;

    if (isFetching) return <Loader className={styles.loader}/>;
    return (
        <Box>
            <div className={styles.content} style={{backgroundImage: `url(${slicedData?.backdrop_path})`}}>
                {innerWidth > 860 &&
                  <Typography variant="h4" className={styles.title}>{t("Aside.serials")}</Typography>}
                {slicedData && <FilmDescription data={slicedData}/>}
            </div>
            {dataPage && <FilmLists dataContent={dataPage} pageName={pageName}/>}
        </Box>
    );
};

export default Serials;
