import React, {useState} from "react";
import styles from "./styles.module.css"
import {Box, Stack, SvgIcon, Switch, Typography} from "@mui/material";
import {PasscodeIcon} from "../../../assets/files";
import clsx from "clsx";
import {Button, CustomModal, VerificationCode} from "../../index";
import {useTranslation} from "react-i18next";

const ProfileAdult = () => {
    const {t} = useTranslation("translations");
    const [code, setCode] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(false)


    const handleChange = (newCode: string) => {
        setError("");
        setCode(newCode);
    };
    const handleVerify = () => {
        setCode("")
        setActive(!active)
        setOpenModal(!openModal)
    }
    return (
        <div className={styles.root}>
            <Box className={clsx(styles.box, active && styles.active)}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} gap={1} alignItems={"baseline"}>
                        <SvgIcon><PasscodeIcon/></SvgIcon>
                        <Stack direction={"column"} gap={1}>
                            {active ? <Typography variant={"h2"}>{t("Settings.pinStatusEnabled")}</Typography> :
                                <Typography variant={"h2"}>{t("Settings.pinStatusDisabled")}</Typography>}
                            <Typography variant={"h4"}>{t("Settings.pinRequirement")}</Typography>
                        </Stack>
                    </Stack>
                    <Switch value={active} onClick={() => setOpenModal(!openModal)}/>
                </Stack>
            </Box>

            <CustomModal open={openModal}>
                <Box display={"grid"} justifyItems={"center"} gap={2}>
                    <Typography variant={"h1"} align={"center"}>{t("Settings.addPinToAdultSection")}</Typography>
                    <Typography variant={"subtitle2"} textAlign={"center"}>{t("Settings.verifiedEmail")}
                        v************@gmail.com.</Typography>
                    <Box mt={4} mb={4}>
                        <VerificationCode value={code} onChange={handleChange} error={error}/>

                    </Box>
                    <Stack direction={"column"} gap={2} width={"100%"} mt={2}>
                        <Button color="primary" variant="contained" fullWidth disabled={code.length !== 4}
                                onClick={handleVerify}>{t("Settings.createPin")}</Button>
                        <Button fullWidth color="secondary" variant="contained"
                                onClick={() => setOpenModal(!openModal)}>{t("Buttons.cancel")}</Button>
                    </Stack>
                </Box>
            </CustomModal>
        </div>
    );
};

export default ProfileAdult;
