import React, {useState} from "react";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import styles from "./styles.module.css";
import {CheckIcon} from "../../../assets/files";
import {updateProfile} from "../../../services/userAPI";
import {fetchUserProfile} from "../../../store/profile/thunks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store";
import {languageData} from "../types";
import {setUser} from "../../../store/reducer";


interface IProps {
    language?: string | null;
    id?: number | string | null
}

const ProfileLanguage: React.FC<IProps> = (props) => {
    const {language, id} = props;
    const dispatch: AppDispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState<string>(language || "en");
    const handleListItemClick = async (key: string) => {
        if (id) {
            setSelectedIndex(key);
            try {
                const res = await updateProfile(id, {language: key});
                if (res) {
                    window.location.reload()
                    dispatch(setUser(res))
                    dispatch(fetchUserProfile(id));
                }
            } catch (err) {
                console.log("err", err);
            }
        }
    };


    return (
        <div className={styles.root}>
            <List className={styles.list}>
                {languageData.map(({name, key}) => (
                    <ListItemButton
                        key={key}
                        onClick={() => handleListItemClick(key)}
                        selected={selectedIndex === key}
                    >
                        <ListItemIcon>{selectedIndex === key && <CheckIcon/>}</ListItemIcon>
                        <ListItemText primary={name} className={styles.name}/>
                    </ListItemButton>
                ))}
            </List>
        </div>
    );
};

export default ProfileLanguage;
