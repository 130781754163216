import React, {useState} from "react";
import styles from "./MovieCard.module.css";
import {Card, CardContent, CardMedia, IconButton, Popover, Skeleton, Stack, Typography} from "@mui/material";
import CustomChip from "../../Chip/Chip";
import {Checked, FullScreen, Plus, Star} from "../../../assets/files";
import {IData, IRelated} from "../types";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../routes/routes";
import {IProfileList} from "../../../store/profile/profileSlice";
import {addToList} from "../../../services/userAPI";
import Keys from "../../../constants/helper";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store";
import {getContentDetails} from "../../../services/contentAPI";
import {openVideoPlayer} from "../../../store/player/playerSlice";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {useNavTitle} from "../../Aside/IAside";
import useResize from "../../../hooks/useResize";

interface IProps {
    data?: IData | IRelated | IProfileList;
    isProgram?: boolean
}

const MovieCard: React.FC<IProps> = (props) => {
    const {innerWidth} = useResize()
    const {t} = useTranslation("translations");
    const {data, isProgram} = props
    const navTitles = useNavTitle();
    const profile_id = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const genreArray = data?.genders && data?.genders.split(",");
    const [isImageLoading, setImageLoading] = useState(true);
    const handleImageLoad = () => setImageLoading(false);
    const isMobile = innerWidth < 860


    const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
        if (isMobile) {
            handleNavigate(data?.id)
        } else {
            if (anchorEl === event.currentTarget) {
                setAnchorEl(null);
            } else {
                setAnchorEl(event.currentTarget);
            }
        }

    };

    const handleNavigate = (id: number | string | undefined) => {
        navigate(`${routes.details.details}/${id}`)
    }

    const handleAddList = async () => {
        try {
            if (profile_id || data?.id) await addToList(profile_id as string, {content: data?.id as number});
        } catch (error) {
            console.error("Failed to add to list", error);
        }
    }

    const handleFullScreen = async () => {
        if (profile_id && data?.id) {
            await getContentDetails(profile_id, Number(data.id)).then((res) => {
                if (res?.video) {
                    dispatch(openVideoPlayer({
                        id: Number(data?.id),
                        title: res?.title || "",
                        type: res?.type || "",
                        videoData: res.video,
                    }));
                }
            }).catch((error) => {
                console.error("Error fetching content details:", error);
            })
        } else {
            console.warn("Profile ID or data ID is missing.");
        }
    };
    const translatedTitle = navTitles.find(item => item.name === data?.type)?.label || data?.type;
    return (
        <>
            <div className={clsx(styles.card, isProgram && styles.cardProgram)}
                 onClick={handleCardClick}>
                {isImageLoading && (
                    <Skeleton variant="rectangular" animation="wave"
                              className={clsx(styles.poster, isProgram && styles.posterProgram)}/>
                )}
                <img src={!isProgram ? data?.poster_path : data?.backdrop_path} alt={"MovieItem"}
                     onLoad={handleImageLoad}
                     className={clsx(styles.poster, isProgram && styles.posterProgram, isImageLoading ? styles.hidden : "")}
                />
                {data?.type === "LIVE" && <div className={clsx(styles.chipLive, isProgram && styles.chipLiveProgram)}>
                  <CustomChip label={t("Details.live")} color="error"/>
                </div>}
                {isProgram && <span className={styles.posterProgramTitle}>{t("Details.chanelName")}</span>}
                {isProgram && <div className={styles.posterProgramSubtitle}>
                  <Typography variant={"h4"}>{data?.title}</Typography>
                  <Typography variant={"body1"}>{data?.duration}</Typography>
                </div>}

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Card className={styles.popover_card}>
                        <span className={styles.type}>{translatedTitle}</span>
                        <CardMedia onClick={() => handleNavigate(data?.id)}
                                   className={styles.cardImage}
                                   sx={{height: 240, cursor: "pointer"}}
                                   image={data?.backdrop_path}
                                   title="Movie Background"
                        />
                        <CardContent className={styles.content}>
                            <Typography gutterBottom variant="h5" component="h5">
                                {data?.title}
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems={"center"} gap={1}>
                                <div className={styles.rating}><Star/> {data?.rating}</div>
                                {data?.duration &&
                                  <CustomChip label={data?.duration} color={"default"} variant={"filled"}/>}
                                {data?.year &&
                                  <CustomChip label={data?.year.toString()} color={"default"} variant={"filled"}/>}
                                {data?.country &&
                                  <CustomChip label={data?.country} color={"default"} variant={"filled"}/>}
                                {/*<CustomChip label={"Full HD"} color={"secondary"} variant={"filled"}/>*/}
                                {data?.classification &&
                                  <CustomChip label={data?.classification} color={"primary"} variant={"filled"}/>}
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems={"center"} mt={2} flexWrap={"wrap"} gap={1}>
                                {genreArray?.map((genre: string) => (
                                    <CustomChip key={genre} label={genre} color={"default"} variant={"filled"}/>
                                ))}
                            </Stack>
                            <Stack direction="row" spacing={1} className={styles.controls}>
                                <div className={styles.controlsLeft}>
                                    <IconButton onClick={handleAddList}><Plus/></IconButton>
                                    <IconButton> <Checked/></IconButton>
                                </div>
                                <div className={styles.controlsRight}>
                                    <IconButton onClick={handleFullScreen}><FullScreen/></IconButton>
                                </div>
                            </Stack>
                        </CardContent>
                    </Card>
                </Popover>
            </div>
        </>
    );
};

export default MovieCard;
