import React from "react";
import {Tab, Tabs} from "@mui/material";
import styles from "../MovieCard/MovieCard.module.css";
import {MovieCard} from "../../index";
import {IData} from "../types";

interface IProps {
    data?: IData[];
    isProgram?: boolean
}
const CardSlider: React.FC<IProps> = (props) => {
    const {data, isProgram} = props
    return (
            <Tabs
                className={styles.tabs}
                value={false}
                variant="scrollable"
                aria-label="scrollable force tabs example"
                indicatorColor={"secondary"}
            >
                {data?.map((e: IData) => (
                    <Tab key={e.id} className={styles.tab} label={<MovieCard data={e} isProgram={isProgram}/>}/>
                ))}
            </Tabs>

    );
};

export default CardSlider;
