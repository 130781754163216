import {
    SearchIcon,
    MoviesIcon,
    HomeIcon,
    EpicIcon,
    SeriesIcon,
    TvIcon,
    BallIcon,
    AdultsIcon,
    SettingIcon,
} from "../../assets/files";
import {ReactNode} from "react";
import {routes} from "../../routes/routes";
import {useTranslation} from "react-i18next";

export interface INavData {
    key: number,
    label: string,
    icon: ReactNode | any,
    path?: string
}

export interface INavTitle {
    label: string,
    name: string
}

export const useNavData = (): INavData[] => {
    const {t} = useTranslation("translations");
    return [
        {key: 0, label: t("Aside.search"), icon: SearchIcon, path: routes.search},
        {key: 1, label: t("Aside.home"), icon: HomeIcon, path: routes.home},
        {key: 2, label: t("Aside.myEpic"), icon: EpicIcon, path: routes.list},
        {key: 3, label: t("Aside.movie"), icon: MoviesIcon, path: routes.movies},
        {key: 4, label: t("Aside.serials"), icon: SeriesIcon, path: routes.serials},
        {key: 5, label: t("Aside.liveTV"), icon: TvIcon, path: routes.live},
        {key: 6, label: t("Aside.sports"), icon: BallIcon, path: routes.sports},
        {key: 7, label: t("Aside.adults"), icon: AdultsIcon, path: routes.adults},
        {key: 8, label: t("Aside.settings"), icon: SettingIcon, path: routes.settings},
    ];
};


export const useNavKidsData = (): INavData[] => {
    const {t} = useTranslation("translations");
    return [
        {key: 0, label: t("Aside.search"), icon: SearchIcon, path: routes.search},
        {key: 1, label: t("Aside.home"), icon: HomeIcon, path: routes.home},
        {key: 2, label: t("Aside.myEpic"), icon: EpicIcon, path: routes.list},
        {key: 3, label: t("Aside.movie"), icon: MoviesIcon, path: routes.movies},
        {key: 4, label: t("Aside.serials"), icon: SeriesIcon, path: routes.serials},
        {key: 5, label: t("Aside.liveTV"), icon: TvIcon, path: routes.live},
        {key: 8, label: t("Aside.settings"), icon: SettingIcon, path: routes.settings},
    ];
};


export const useNavTitle = (): INavTitle[] => {
    const {t} = useTranslation("translations");
    return [
        {label: t("Aside.serials"), name: "SERIE"},
        {label: t("Aside.movie"), name: "MOVIE"},
        {label: t("Details.live"), name: "LIVE"},
    ];
};



