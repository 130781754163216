// CardSerials.tsx
import React from "react";
import {LinearProgress, Stack, Typography} from "@mui/material";
import {ISeasonsChapters} from "../types";
import styles from "./CardSerials.module.css";
import {ISerialData, openVideoPlayer} from "../../../store/player/playerSlice";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

interface IProps {
    data?: ISeasonsChapters;
    season_number?: number;
    chapters?: ISerialData[] | any
}

const CardSerials: React.FC<IProps> = ({data, chapters, season_number}) => {
    const {id} = useParams();
    const dispatch = useDispatch();

    const handleOpenPlayVideo = () => {
        if (data?.video && id) {
            dispatch(openVideoPlayer({
                id: Number(id),
                title: data?.title || "",
                videoData: data?.video,
                // Include serial data if needed
                serialData: {
                    chapters,
                    episodeTitle: data?.title,
                    seasonNumber: season_number as number, // Assuming data has season_number
                    episodeNumber: data?.chapter_number,
                },
            }));
        }
    };

    return (
        <Stack direction={"row"} alignItems={"center"} gap={4} onClick={handleOpenPlayVideo} className={styles.root}>
            <div className={styles.card}>
                <img src={data?.poster} alt={"MovieItem"} loading={"lazy"} className={styles.poster}/>
                {data?.chapter_number === 0 && (
                    <div className={styles.progress_wrapper}>
                        <LinearProgress variant="determinate" value={25} className={styles.progress}/>
                    </div>
                )}
            </div>
            <Stack gap={"40px"} className={styles.root_right}>
                <Stack gap={2}>
                    <Typography variant={"h5"}>Episode {Number(data?.chapter_number)}</Typography>
                    <Typography variant={"h2"}>{data?.title}</Typography>
                </Stack>
                <Typography variant={"h5"}>{data?.duration}</Typography>
            </Stack>
        </Stack>
    );
};

export default CardSerials;
