import React, {useEffect} from "react";
import {FilmDescription, Loader, FilmLists} from "../../components";
import styles from "./Sports.module.css";
import {Box, Typography} from "@mui/material";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {useTranslation} from "react-i18next";


const Sports = () => {
    const {t} = useTranslation("translations");
    const dispatch: AppDispatch = useDispatch();
    const pageName = "SPORTS";
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);

    // Fetch page content
    useEffect(() => {
        dispatch(fetchPage(pageName));
    }, [dispatch]);


    const slicedData = dataPage ? dataPage[1]?.contents[1] : null;

    if (isFetching) return <Loader className={styles.loader}/>;

    return (
        <Box>
            <div className={styles.content} style={{backgroundImage: `url(${slicedData?.backdrop_path})`}}>
                <Typography variant="h4" className={styles.title}>{t("Aside.sports")}</Typography>
                {slicedData && <FilmDescription data={slicedData}/>}
            </div>
            {dataPage && <FilmLists dataContent={dataPage} pageName={pageName} isProgram/>}
        </Box>
    );
};

export default Sports;
