import React, {useState} from "react";
import {FormControl, TextField, Typography, InputAdornment, IconButton} from "@mui/material";
import clsx from "clsx";
import {CustomInputProps} from "./IInputField";
import styles from "./InputField.module.css";
import {EyeIcon, SearchIcon} from "../../assets/files";

const CustomInput: React.FC<CustomInputProps> = (props) => {
    const {
        onChange,
        onBlur,
        disabled,
        label,
        name,
        placeholder,
        className,
        startIcon,
        helperText,
        type = "text",
        endIcon,
        error,
        value,
        ...otherProps
    } = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const renderEndIcon = () => (
        <InputAdornment position="end">
            {type === "password" && (
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                >
                    <EyeIcon/>
                </IconButton>
            )}
        </InputAdornment>
    );
    const renderStartIcon = () => (
        <InputAdornment position="start">
                <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                >
                    <SearchIcon/>
                </IconButton>
        </InputAdornment>
    );


    return (
        <div className={styles.input}>
            {label && <Typography className={styles.title}>{label}</Typography>}
            <FormControl className={clsx(styles.inputWrapper, error && styles.error)} focused={false}>
                <TextField
                    name={name}
                    disabled={disabled}
                    className={className}
                    value={value}
                    onBlur={onBlur}
                    autoComplete="email"
                    error={error}
                    helperText={helperText}
                    onChange={onChange}
                    type={type === "password" && showPassword ? "text" : type}
                    placeholder={placeholder}
                    InputProps={{endAdornment: renderEndIcon(), startAdornment: startIcon && renderStartIcon()}}
                    {...otherProps}
                />
            </FormControl>
        </div>
    );
};

export default CustomInput;
