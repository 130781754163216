import React, {ReactNode, useEffect, useState} from "react";
import Keys from "./constants/helper";
import muiThemeKIDS from "./theme/muiThemeKIDS";
import muiTheme from "./theme/muiTheme";
import {ThemeProvider} from "@mui/material";

interface IProps {
    children: ReactNode;
}

const getRoleTheme = () => {
    const role = localStorage.getItem(Keys.EPIC_USER_ROLE);
    return role === "KIDS" ? muiThemeKIDS : muiTheme;
};

const useThemeWithStorage = () => {
    const [theme, setTheme] = useState(getRoleTheme());

    useEffect(() => {
        const handleRoleChange = () => setTheme(getRoleTheme());
        const originalSetItem = localStorage.setItem;
        localStorage.setItem = function (key, value) {
            originalSetItem.call(this, key, value);
            if (key === Keys.EPIC_USER_ROLE) {
                handleRoleChange();
            }
        };
        window.addEventListener("storage", handleRoleChange);
        return () => {
            window.removeEventListener("storage", handleRoleChange);
            localStorage.setItem = originalSetItem; // Restore original setItem
        };
    }, []);

    return theme;
};

const Theme: React.FC<IProps> = ({children}) => {
    const theme = useThemeWithStorage();

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
