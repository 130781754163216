import React, {useState} from "react";
import {Button, CustomInput} from "../../index";
import styles from "./styles.module.css"
import {updateProfile} from "../../../services/userAPI";
import {fetchUserProfile} from "../../../store/profile/thunks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store";
import {Box} from "@mui/material";
import {setUser} from "../../../store/reducer";
import {useTranslation} from "react-i18next";

interface IProps {
    name?: string;
    id?: number | string | null
}

const ProfileName: React.FC<IProps> = (props) => {
    const {name, id} = props
    const {t} = useTranslation("translations");
    const dispatch: AppDispatch = useDispatch()
    const [value, setValue] = useState<string>(name || "")
    const [loading, setLoading] = useState<boolean>(false)

    const handleUpdate = async () => {
        if (id && name !== value) {
            setLoading(true)
            try {
                const res = await updateProfile(id, {name: value})
                console.log("res", res)
                if (res) {
                    dispatch(fetchUserProfile(id))
                    dispatch(setUser(res))
                }
            } catch (err) {
                console.log("err", err)
            } finally {
                setLoading(false)
            }
        }
    }
    return (
        <Box className={styles.content}>
            <CustomInput
                name={"name"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <Button sx={{maxWidth: "420px"}} fullWidth color="secondary" variant="contained" onClick={handleUpdate}
                    isLoading={loading}>
                {t("Buttons.saveName")}
            </Button>
        </Box>
    );
};

export default ProfileName;
