import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import { routes } from "../../routes/routes";
import helper from "../../constants/helper";

const PublicOutlet = () => {
    const location = useLocation()
    const token = localStorage.getItem(helper.EPIC_JWT);
    const profileID = localStorage.getItem(helper.EPIC_PROFILE_ID);

    if (location.pathname === "/") {
        return <Navigate to={routes.login} />;
    }

    if (token && profileID) {
        // Redirect to the home page if both token and profile ID are present
        return <Navigate to={routes.home} />;
    } else if (token && !profileID) {
        // Redirect to profile creation if only the token is present
        return <Navigate to={routes.profile} />;
    }

    // Allow access to public routes (login, forgot password, etc.)
    return <Outlet />;
};

export default PublicOutlet;
