export const routes = {
    // Auth
    login: "/login",
    forgotPassword: "/forgot_password",
    notFound: "/*",
    // Profile
    profile: "/profile",
    profileEdit: "/profile_edit",
    profileCreate: "/profile_create",
    home: "/home",
    movies: "/movies",
    serials: "/serials",
    adults: "/adults",
    settings: "/settings",
    sports: "/sports",
    live: "/live",
    search: "/search",
    homeCategories: "/home_categories",
    details: {
        details: "/details",
        details_setup: "/details/details_setup",
        details_serials: "/details/details_serials",
    },
    detailsRecommendation: "/details_recommendation",
    list: "/list",
}
