export const OptionSize = [
    {value: "small"},
    {value: "medium"},
    {value: "big"},
]

export const OptionEffects = [
    {value: "no-effect"},
    {value: "effect"},
]
export const OptionAudio = [
    {value: "english"},
    {value: "spanish"},
    // {value: "portuguese"},
    // {value: "korean"},
    // {value: "turkish"},
    // {value: "arabic"},
]
export const OptionLanguage = [
    {value: "english"},
    {value: "spanish"},
    // {value: "portuguese"},
    // {value: "korean"},
    // {value: "turkish"},
    // {value: "arabic"},
]


