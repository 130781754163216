import React, {useEffect, useState} from "react";
import {CardContinueWatching, Loader, MovieCard} from "../../components";
import {Box, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import styles from "./MyEpic.module.css";
import CustomTabs from "../../components/Tabs/Tabs";
import {DataSelectedListType, getDataContinueWatch, getDataMyList, IKey, useDataList} from "./data";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchProfileList, fetchProfileWatchingList} from "../../store/profile/thunks";
import {IProfileList} from "../../store/profile/profileSlice";
import Keys from "../../constants/helper";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";
import {NextIcon} from "../../assets/files";

const MyEpic: React.FC = () => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize();
    const dispatch: AppDispatch = useDispatch();

    const profile_id = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const isMobile = innerWidth < 860;

    const [selectedIndex, setSelectedIndex] = useState<IKey>(isMobile ? 0 : 1);
    const [selected, setSelected] = useState<number>(0);
    const [reset, setReset] = useState<boolean>(false);
    console.log("selected", selected)
    const {profileList, isFetching} = useSelector((state: RootState) => state.profile);
    const dataList = useDataList();

    const dataSelectedList: DataSelectedListType = {
        1: getDataMyList(t),
        2: getDataContinueWatch(t),
    };

    useEffect(() => {
        if (!profile_id) return;

        if (selectedIndex === 1) {
            dispatch(fetchProfileList(profile_id));
        } else if (selectedIndex === 2) {
            dispatch(fetchProfileWatchingList(profile_id));
        }
    }, [profile_id, selectedIndex, dispatch]);

    const handleListItemClick = (index: IKey) => {
        setSelectedIndex(index);
        setReset(!reset);
    };

    const renderListItems = () => (
        <List className={styles.list}>
            {dataList.map((item) => (
                <ListItemButton
                    key={item.key}
                    selected={selectedIndex === item.key}
                    onClick={() => handleListItemClick(item.key as IKey)}
                >
                    <ListItemIcon>
                        <item.icon/>
                    </ListItemIcon>
                    <ListItemText primary={item.name} className={styles.name}/>
                    {isMobile && (
                        <ListItemIcon>
                            <NextIcon/>
                        </ListItemIcon>
                    )}
                </ListItemButton>
            ))}
        </List>
    );

    const renderContent = () => {
        if (isFetching) {
            return <Loader className={styles.loader}/>;
        }

        return (
            <Stack direction="row" flexWrap="wrap" gap="1rem" mt={5}
                   justifyContent={isMobile ? "center" : "flex-start"}>
                {profileList?.map((item: IProfileList, index: number) => (
                    <div key={index} className={selectedIndex === 2 ? styles.fullCard : ""}>
                        {selectedIndex === 2 ? <CardContinueWatching data={item}/> : <MovieCard data={item}/>}
                    </div>
                ))}
            </Stack>
        );
    };

    return (
        <div className={styles.root}>
            {!isMobile && <Typography variant="h4" className={styles.title}>{t("Aside.myEpic")}</Typography>}
            <Stack direction="row" gap={6} mt={8} alignItems="flex-start">
                {(selectedIndex === 0 || !isMobile) && renderListItems()}
                {selectedIndex !== 0 && (
                    <Box sx={{width: "100%"}} display="grid">
                        <CustomTabs list={dataSelectedList[selectedIndex]} reset={reset} withoutAll
                                    setSelected={setSelected}/>
                        {renderContent()}
                    </Box>
                )}
            </Stack>
        </div>
    );
};

export default MyEpic;
