import clsx from "clsx";
import React, {ReactNode} from "react";
import {Fade, Modal, ModalProps} from "@mui/material";
import styles from "./CustomModal.module.css";

export type CustomModalProps = {
    className?: string;
    children?: ReactNode;
} & ModalProps

const CustomModal = ({children, className, ...props}: CustomModalProps) => {
    return (
        <Modal
            {...props}
            slotProps={{backdrop: {timeout: 100}}}
        >
            <Fade in={props.open}>
                <div className={clsx(styles.modal, className)}>
                    {children}
                </div>
            </Fade>
        </Modal>

    );
};

export default CustomModal;
