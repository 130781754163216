import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Keys from "../constants/helper";

interface AuthState {
    user?: any;
    token: string | null;
    profile?: number | any
}

const token = localStorage.getItem(Keys.EPIC_JWT);
const profile = localStorage.getItem(Keys.EPIC_PROFILE_ID);
const user_data = localStorage.getItem(Keys.EPIC_USER_DATA);
const parsedUserData = user_data ? JSON.parse(user_data) : null;


const initialState: AuthState = {
    user: parsedUserData,
    token: token,
    profile: profile,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            localStorage.setItem(Keys.EPIC_JWT, action.payload);
            state.token = action.payload;
        },
        setTokenRefresh: (state, action: PayloadAction<string>) => {
            localStorage.setItem(Keys.EPIC_REFRESH_TOKEN, action.payload);
            state.token = action.payload;
        },
        setProfileID: (state, action: PayloadAction<string | any>) => {
            localStorage.setItem(Keys.EPIC_PROFILE_ID, action.payload);
            state.profile = action.payload;
        },
        setRole: (state, action: PayloadAction<string>) => {
            localStorage.setItem(Keys.EPIC_USER_ROLE, action.payload);
            state.profile = action.payload;
        },
        setUser: (state, action: PayloadAction<any>) => {
            localStorage.setItem(Keys.EPIC_USER_DATA, JSON.stringify(action.payload));
            state.user = action.payload;
        },
        logoutFinish: (state) => {
            state.token = null;
            state.user = null;
            localStorage.removeItem(Keys.EPIC_JWT);
            localStorage.removeItem(Keys.EPIC_PROFILE_ID);
            localStorage.removeItem(Keys.EPIC_REFRESH_TOKEN);
            localStorage.removeItem(Keys.EPIC_USER_ROLE);
            localStorage.removeItem(Keys.EPIC_USER_DATA);
        }
    }
});

export const {setUser,setRole, setProfileID,setTokenRefresh, setToken, logoutFinish} = authSlice.actions;
export default authSlice.reducer;
