import API from "./http";


export const getGenreContent = async (id: string | number | undefined) => API.get(`/page/${id}`);

export const getPage = async (page: string) => API.get(`/page/by_name/${page}`);

export const getSearchPage = async (query: string, page: number | string) => {
    const searchQuery = query ? `search=${query}&` : "";
    return API.get(`/content/content?${searchQuery}page=${page}`);
};

// export const getAllContent = async () => API.get("/content/content/all/HOME/?video=true");
//
// export const getPageCategories = async () => API.get("/page/category_list/HOME/");
//
export const getContentDetails = async (userId: string | number | undefined, id: string | number | undefined) => {
    return API.get(`/content/content/${id}`, {
        headers: {
            "profile": userId, // or use a specific header name that suits your needs
        },
    });
};
export const getRelatedContent = async (id: string | number | undefined) => API.get(`/content/content/${id}/related_content`);

