import axios, {AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import helper from "../constants/helper";
import Keys from "../constants/helper";
import {forceLogout} from "./userAPI";

const baseURL = process.env.REACT_APP_API_URL || "";

// Set the base URL for Axios
axios.defaults.baseURL = baseURL;

// Separate Axios instance for handling token refresh to avoid using interceptors
const refreshInstance = axios.create({
    baseURL,
});

const refreshAuthToken = async (): Promise<string | null> => {
    try {
        // Retrieve the refresh token from localStorage
        const refreshToken = localStorage.getItem(Keys.EPIC_REFRESH_TOKEN);
        console.log(refreshToken);
        console.log("Attempting to refresh token...");

        if (!refreshToken) {
            // Handle missing refresh token
            console.warn("No refresh token available, redirecting to login.");
            // Optionally, redirect the user to login here (e.g., navigate('/login'))
            return null; // Return null to halt further token refresh process
        }
        console.log("refreshToken", refreshToken)
        // Request a new access token using the refresh token
        const {data} = await refreshInstance.post("/token/refresh/", {refresh: refreshToken});
        // Store the new access token in localStorage
        localStorage.setItem(Keys.EPIC_JWT, data.access);
        localStorage.setItem(Keys.EPIC_REFRESH_TOKEN, data.refresh);

        // Update the refresh token if a new one is provided
        // if (data.refresh) {
        //     localStorage.setItem(Keys.EPIC_REFRESH_TOKEN, data.refresh);
        // }

        return data.access; // Return the new access token
    } catch (error) {
        console.error("Failed to refresh token:", error);

        // Clear tokens from storage in case of failure
        localStorage.removeItem(Keys.EPIC_JWT);
        localStorage.removeItem(Keys.EPIC_REFRESH_TOKEN);

        // Optionally, redirect the user to the login page here
        // navigate('/login');

        return null; // Return null as the token refresh process failed
    }
};




// Response Interceptor for handling 401 Unauthorized and token refresh
axios.interceptors.response.use(
    (response) => response, // Return the response if it's successful
    async (error) => {
        const originalRequest = error.config;

        // Handle 401 (Unauthorized) error by refreshing the token
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Avoid retry loop
            const newToken = await refreshAuthToken();

            if (newToken) {
                // Update the Authorization header with the new token
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                return axios(originalRequest); // Retry original request with new token
            } else {
                forceLogout()
            }

        }

        return Promise.reject(error);
    }
);

// Request Interceptor for adding Authorization header
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(helper.EPIC_JWT);
    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
    }
    return config;
});

// API helper functions
const API = {
    get: (url: string, config?: AxiosRequestConfig) =>
        axios.get(url, config).then(({data}) => data),

    post: <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
        axios.post(url, data, config).then(({data}) => data),

    put: <T>(url: string, data?: T, config?: AxiosRequestConfig) =>
        axios.put(url, data, config).then(({data}) => data),

    delete: (url: string, config?: AxiosRequestConfig) =>
        axios.delete(url, config).then(({data}) => data),
};

export default API;
