import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IRelated, ISeasonsChapters} from "../../components/Cards/types";

interface IVideoData {
    id: number | null;
    subtitle: string;
    url: string;
}

export interface ISerialData {
    seasonNumber: number | null;
    episodeNumber: number | null;
    episodeTitle: string;
    chapters?: ISeasonsChapters[]
}

interface VideoState {
    id: number | null;
    isVideoPlayerOpen: boolean;
    play: boolean;
    progress: number; // Progress percentage
    duration: number; // Duration in seconds
    title: string;
    type: string;
    videoData: IVideoData;
    tvData: IRelated | null;
    serialData: any | null;  // Add serialData for serial episodes
    isFullscreen: boolean;
}

const initialState: VideoState = {
    id: null,
    play: false,
    progress: 0,
    duration: 0,
    title: "",
    type: "",
    isVideoPlayerOpen: false,
    videoData: {
        id: null,
        subtitle: "",
        url: ""
    },
    tvData: null,
    serialData: null,  // Initialize with null for non-serials
    isFullscreen: false,
};

interface IVideoPlayer {
    title: string,
    videoData: IVideoData,
    id: number | null,
    type?: string | undefined,
    serialData?: ISerialData | null
    tvData?:  null

}

const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {
        openVideoPlayer(state, action: PayloadAction<IVideoPlayer>) {
            state.isVideoPlayerOpen = true;
            state.title = action.payload.title;
            state.type = action.payload.type || "";
            state.videoData = action.payload.videoData;
            state.serialData = action.payload.serialData || null;  // Handle serial data if provided
            state.play = true;
            state.isFullscreen = true;
            state.id = action.payload.id;
        },
        setVideoPlayerOpen(state, action: PayloadAction<boolean>) {
            state.isVideoPlayerOpen = action.payload;
        },
        closeVideoPlayer(state) {
            state.isVideoPlayerOpen = false;
            state.play = false;
            state.progress = 0;
            state.duration = 0;
            state.title = "";
            state.id = null;
            state.videoData = {
                id: null,
                subtitle: "",
                url: ""
            };
            state.serialData = null;  // Reset serial data
            state.isFullscreen = false;
        },
        setPlay(state, action: PayloadAction<boolean>) {
            state.play = action.payload;
        },
        setProgress(state, action: PayloadAction<number>) {
            state.progress = action.payload;
        },
        setDuration(state, action: PayloadAction<number>) {
            state.duration = action.payload;
        },
        setFullscreen(state, action: PayloadAction<boolean>) {
            state.isFullscreen = action.payload; // Set fullscreen state
        },
        resetVideo(state) {
            state.play = false;
            state.progress = 0;
            state.duration = 0;
            state.isVideoPlayerOpen = false;
            state.title = "";
            state.id = null;
            state.videoData = {
                id: null,
                subtitle: "",
                url: ""
            };
            state.serialData = null;  // Reset serial data
            state.isFullscreen = false;
        },
    },
});

export const {
    setPlay,
    setProgress,
    setDuration,
    setFullscreen,
    openVideoPlayer,
    closeVideoPlayer,
    resetVideo,
    setVideoPlayerOpen,
} = videoSlice.actions;

export default videoSlice.reducer;
