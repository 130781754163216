import React, {useEffect, useState} from "react";
import {FilmDescription,  Loader, VerificationCode, FilmLists} from "../../components";
import styles from "./Adults.module.css";
import {Box, Typography} from "@mui/material";
import {fetchRelatedContent} from "../../services/userAPI";
import Keys from "../../constants/helper";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {useTranslation} from "react-i18next";


const Adults = () => {
    const {t} = useTranslation("translations");
    const dispatch: AppDispatch = useDispatch();
    const pageName = "ADULT";
    const profileID = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);
    const [code, setCode] = useState<string>("");
    const [validate, setValidate] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (code.length === 4) {
            fetchRelatedContent(profileID, {password: code})
                .then((res) => {
                    if(res) {
                        setValidate(true);
                        sessionStorage.setItem("adultsPageValidated", "true");
                    }
                })
                .catch(() => setError("Incorrect password"));
        }
    }, [code, profileID]);

    // Fetch page content
    useEffect(() => {
        dispatch(fetchPage(pageName));
    }, [dispatch]);

    useEffect(() => {
        const validated = sessionStorage.getItem("adultsPageValidated");
        if (validated === "true") {
            setValidate(true);
        }
    }, []);


    const slicedData = dataPage ? dataPage[0]?.contents[0] : null;

    if (isFetching) return <Loader className={styles.loader}/>;

    return (
        <>
            {!validate ? (
                <div className={styles.formPin}>
                    <Typography variant={"subtitle1"}>{t("Authorization.enterYourPin")}</Typography>
                    <Typography variant={"subtitle2"}>{t("Authorization.enterYourPinByUsingTheNumber")}</Typography>
                    <VerificationCode value={code} onChange={(value) => {
                        setCode(value);
                        setError("");
                    }}/>
                    <span className={styles.error}>{error}</span>
                </div>
            ) : (
                <Box>
                    <div className={styles.content} style={{backgroundImage: `url(${slicedData?.backdrop_path})`}}>
                        <Typography variant="h4" className={styles.title}>{t("Aside.adults")}</Typography>
                        {slicedData && <FilmDescription data={slicedData}/>}
                    </div>
                    {dataPage && <FilmLists dataContent={dataPage} pageName={pageName} isProgram/>}
                </Box>
            )}
        </>
    );
};

export default Adults;
