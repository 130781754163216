import React from "react";
import VerificationInput from "react-verification-input";
import {Box, Typography} from "@mui/material";
import styles from "./verificationCode.module.css";
import {IVerificationCode} from "./IVerificationCode";
import clsx from "clsx";

const VerificationCode: React.FC<IVerificationCode> = (props) => {
    const {className, error, name, value, onChange} = props
    return (
        <Box className={clsx(styles.root, className)}>
            <VerificationInput
                autoFocus
                key={name}
                placeholder=""
                validChars="0-9"
                inputProps={{inputMode: "numeric"}}
                length={4}
                classNames={{
                    container: styles.container,
                    character: styles.character,
                    // characterInactive: styles.characterInactive,
                    characterSelected: styles.characterSelected,
                    characterFilled: styles.characterFilled
                }}
                value={value}
                onChange={onChange}
            />
            {Boolean(error) && (
                <Typography className={styles.error} variant="h5">
                    {value ? "Wrong confirmation code, please try again" : error}
                </Typography>
            )}
        </Box>
    );
};

export default VerificationCode;
