import React from "react";

interface IProps {
    size?: number;
    height?: number;
}

export const LogoKids: React.FC<IProps> = ({size, height}) => {
    return (
        <svg width={size || 198} height={height || 68} viewBox="0 0 145 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M69.8413 38.6456C68.2953 38.6456 66.9528 38.3472 65.8137 37.7506C64.6745 37.1267 63.7931 36.2317 63.1693 35.0655V46.9043H55.4395V16.8394H62.1522V20.0534C62.9116 18.8871 63.8473 17.9921 64.9593 17.3683C66.0984 16.7445 67.4681 16.4326 69.0683 16.4326C70.5058 16.4326 71.8212 16.7038 73.0146 17.2462C74.208 17.7887 75.2522 18.5617 76.1472 19.5652C77.0422 20.5687 77.7338 21.7349 78.222 23.0639C78.7102 24.3929 78.9543 25.8575 78.9543 27.4577C78.9543 29.6004 78.5611 31.5125 77.7745 33.194C76.988 34.8756 75.9031 36.2046 74.5199 37.181C73.1637 38.1574 71.6042 38.6456 69.8413 38.6456ZM67.1562 32.1363C67.7258 32.1363 68.2411 32.0142 68.7022 31.7701C69.1904 31.526 69.6108 31.187 69.9633 30.753C70.3159 30.3191 70.5871 29.8173 70.777 29.2478C70.9669 28.6782 71.0618 28.068 71.0618 27.417C71.0618 26.739 70.9533 26.1287 70.7363 25.5863C70.5465 25.0438 70.2617 24.5828 69.882 24.2031C69.5023 23.7962 69.0548 23.4843 68.5394 23.2673C68.0241 23.0504 67.4545 22.9419 66.8307 22.9419C66.4782 22.9419 66.1256 22.9961 65.773 23.1046C65.4475 23.186 65.1356 23.3216 64.8373 23.5114C64.5389 23.6742 64.2406 23.8912 63.9422 24.1624C63.671 24.4336 63.4134 24.7591 63.1693 25.1388V28.8409C63.4134 29.4647 63.7388 30.0343 64.1456 30.5496C64.5796 31.0378 65.0542 31.4311 65.5696 31.7294C66.0849 32.0007 66.6138 32.1363 67.1562 32.1363Z"
                fill="url(#paint0_linear_3995_18609)"/>
            <path d="M80.9665 38.2388V16.8394H88.6964V38.2388H80.9665ZM80.9665 15.0493V8.54004H88.6964V15.0493H80.9665Z"
                  fill="url(#paint1_linear_3995_18609)"/>
            <path
                d="M102.371 38.6456C100.473 38.6456 98.7912 38.3472 97.3266 37.7506C95.8891 37.1539 94.6686 36.3402 93.6651 35.3096C92.6616 34.2518 91.9022 33.0584 91.3868 31.7294C90.8715 30.4005 90.6139 29.0037 90.6139 27.5391C90.6139 25.5863 91.0614 23.7691 91.9564 22.0875C92.8786 20.3788 94.2075 19.0092 95.9434 17.9785C97.7063 16.9479 99.8489 16.4326 102.371 16.4326C104.948 16.4326 107.118 16.975 108.881 18.0599C110.644 19.1448 111.959 20.5416 112.827 22.2503L105.3 24.5285C104.948 24.0132 104.514 23.6199 103.999 23.3487C103.51 23.0775 102.954 22.9419 102.331 22.9419C101.625 22.9419 100.988 23.1317 100.419 23.5114C99.8489 23.864 99.3879 24.3794 99.0353 25.0574C98.7098 25.7355 98.5471 26.5627 98.5471 27.5391C98.5471 28.4884 98.7098 29.3156 99.0353 30.0208C99.3879 30.6988 99.8489 31.2277 100.419 31.6074C100.988 31.96 101.625 32.1363 102.331 32.1363C102.765 32.1363 103.158 32.082 103.51 31.9735C103.863 31.8379 104.188 31.6481 104.487 31.404C104.812 31.1599 105.084 30.8751 105.3 30.5496L112.827 32.8279C112.257 33.967 111.471 34.9705 110.467 35.8384C109.491 36.7064 108.325 37.398 106.968 37.9133C105.64 38.4015 104.107 38.6456 102.371 38.6456Z"
                fill="url(#paint2_linear_3995_18609)"/>
            <path
                d="M127.195 23.1128C126.761 23.2964 126.294 23.4716 125.793 23.6385C125.309 23.7887 124.808 23.9056 124.291 23.989C123.79 24.0725 123.298 24.1142 122.814 24.1142C122.096 24.1142 121.437 23.9974 120.836 23.7637C120.235 23.5133 119.751 23.1128 119.384 22.562C119.033 21.9945 118.858 21.2351 118.858 20.2837V14.2501H117.231V10.67H118.858V6.53906H123.615V10.67H126.194V14.2501H123.615V18.6063C123.615 19.0403 123.707 19.349 123.89 19.5326C124.074 19.6995 124.324 19.783 124.641 19.783C124.875 19.783 125.142 19.7413 125.442 19.6578C125.743 19.5744 126.043 19.4742 126.344 19.3574L127.195 23.1128Z"
                fill="url(#paint3_linear_3995_18609)"/>
            <path
                d="M131.798 23.8388L127.367 10.67H132.249L134.602 19.6077L136.98 10.67H141.412L136.98 23.8388H131.798Z"
                fill="url(#paint4_linear_3995_18609)"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M20.8233 3.24923V0H0V20.9723H3.24788V21.2677H3.24935V26.7323H3.24788V27.3231H7.32418e-05V48L20.8233 48V44.7508H27.1741V48L26.7324 48H48V26.7323H47.9981V27.3231H44.7489V20.9723H47.9981V21.2677H48V8.45102e-06L27.1741 0V3.24923H20.8233ZM12.9964 10.7816H35.1501V17.2H19.4147V21.0462H29.5374V27.3231H19.4147V30.865H35.1501V37.2833H12.9964V27.3231V21.0462V10.7816Z"
                  fill="url(#paint5_linear_3995_18609)"/>
            <defs>
                <linearGradient id="paint0_linear_3995_18609" x1="161" y1="89" x2="184.102" y2="27.276"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
                <linearGradient id="paint1_linear_3995_18609" x1="161" y1="89" x2="184.102" y2="27.276"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
                <linearGradient id="paint2_linear_3995_18609" x1="161" y1="89" x2="184.102" y2="27.276"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
                <linearGradient id="paint3_linear_3995_18609" x1="161" y1="89" x2="184.102" y2="27.276"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
                <linearGradient id="paint4_linear_3995_18609" x1="161" y1="89" x2="184.102" y2="27.276"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
                <linearGradient id="paint5_linear_3995_18609" x1="48" y1="48" x2="14.3129" y2="-8.33504"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF0303"/>
                    <stop offset="0.554164" stopColor="#FFBF00"/>
                    <stop offset="1" stopColor="#30FFF3"/>
                </linearGradient>
            </defs>
        </svg>

    );
};
