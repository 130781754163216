import {createTheme} from "@mui/material/styles";
import {createBreakpoints} from "@mui/system";

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 860, // Custom breakpoint for 860px
        lg: 1200,
        xl: 1536,
    },
});


const muiTheme = createTheme({
    breakpoints,
    typography: {
        fontFamily: "Plus Jakarta Sans, sans-serif"
    },
    palette: {
        error: {
            main: "#FF0402"
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            variants: [
                {
                    props: {variant: "contained", color: "primary"},
                    style: {
                        backgroundColor: "var(--brand-3)",
                        "&:hover, &:active, &:focus": {
                            backgroundColor: "var(--brand-4)",
                        },
                        "&.Mui-disabled": {
                            backgroundColor: "var(--brand-3)",
                            "&:hover": {
                                backgroundColor: "var(--brand-3)",
                            }
                        },
                    }
                },
                {
                    props: {variant: "contained", color: "secondary"},
                    style: {
                        backgroundColor: "#2f302f",
                        border: "1px solid var(--border-disabled)",
                        "&:hover, &:active, &:focus": {
                            backgroundColor: "var(--border-disabled)",
                        }
                    }
                },
                {
                    props: {variant: "outlined", color: "secondary"},
                    style: {
                        height: "54px",
                        fontSize: 16,
                        maxWidth: "100%",
                        fontWeight: 400,
                        justifyContent: "flex-start",
                        color: "var(--white)",
                        backgroundColor: "var(--bgColor-disabled)",
                        border: "none",
                        "&:hover, &:active, &:focus": {
                            backgroundColor: "var(--border-disabled)",
                            border: "none"
                        },

                    }
                }, {
                    props: {variant: "outlined", color: "info"},
                    style: {
                        height: "54px",
                        fontSize: 16,
                        maxWidth: "100%",
                        fontWeight: 400,
                        justifyContent: "flex-start",
                        color: "var(--white)",
                        backgroundColor: "var(--border-disabled)",
                        border: "none",
                        "&:hover, &:active, &:focus": {
                            backgroundColor: "var(--border-disabled)",
                            border: "none"
                        }
                    }
                },
                {props: {fullWidth: true}, style: {width: "100%",}}
            ],
            styleOverrides: {
                root: {
                    padding: "16px 24px",
                    lineHeight: 2,
                    fontWeight: 600,
                    textTransform: "none",
                    width: "fit-content",
                    boxShadow: "none",
                    maxWidth: "520px",
                    minWidth: "110px",
                    maxHeight: "60px",
                    borderRadius: 24,
                    "&.Mui-disabled": {
                        pointerEvents: "all",
                        color: "rgba(255, 255, 255, 0.46)",
                        backgroundColor: "var(--surface)",
                        boxShadow: "none",
                        "&:hover": {
                            color: "rgba(255, 255, 255, 0.46)",
                            backgroundColor: "var(--surface)",
                            boxShadow: "none",
                        },
                    },
                    "@media (max-width: 860px)": {
                        height: "40px",
                        borderRadius: "16px"
                    },
                },
                startIcon: {
                    "& > *:nth-of-type(1)": {
                        fontSize: "24px",  // Example: Change icon size
                    },
                },

            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "0.75rem",
                    fontWeight: 600,
                    whiteSpace: "initial",
                    marginBottom: "0.375rem",
                    color: "rgba(255, 255, 255, 0.76)",
                },
            }
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: "var(--white)",
                    padding: "6px 12px",
                    outline: "none",
                    border: "none",
                    caretColor: "red",
                    backgroundColor: "transparent",
                    caretShape: "block",
                    borderRadius: 0,
                    transition: "border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                    borderBottom: "1px solid var(--border)", // Default borderBottom
                    "&.Mui-error": { // When in error state
                        borderBottom: "none", // Remove the default borderBottom
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderBottom: "1px solid var(--error)", // Apply red bottom border in error state
                    },
                    "&:hover, &:active, &:focus": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                            backgroundColor: "var(--bgColor-disabled)",
                            borderBottom: "1px solid var(--border)", // Apply red bottom border in error state
                            transition: "border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
                        }
                    },
                },
                input: {
                    fontSize: "0.875rem",
                    padding: "0.625rem 0.875rem",
                    lineHeight: 1.5,
                    height: "1.5rem",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: "linear-gradient(rgba(20, 20, 20, .5), rgba(20, 20, 20, 1))",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    margin: "6px 12px",
                    backgroundColor: "#fff",
                    opacity: "4%"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    zIndex: 1,
                },
                paper: {
                    border: "1px solid var(--border-disabled)",
                    borderRight: "1px solid var(--border-disabled)",
                    height: "fit-content",
                    top: "8rem",
                    borderRadius: "16px",
                    position: "inherit",
                    left: "4rem",
                    [breakpoints.down("md")]: {
                        left: 0,
                        top: 0,
                        height: "100%",
                        borderRadius: 0,
                        background: "var(--base-night)",
                        boxShadow: "none"
                    }

                },
            },

        }, MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    right: "auto",
                    width: "fit-content",
                    height: "fit-content",
                    boxShadow: "none",
                    position: "inherit"

                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    marginLeft: 0,

                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    marginBottom: ".5rem",
                    backgroundColor: "rgba(255, 255, 255, 0.08)"
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    opacity: 0.5,
                    padding: "16px 0!important",
                    margin: "0 16px",
                    height: "60px",
                    transition: "ease-in-out 5ms",
                    backgroundColor: "transparent",
                    "&:hover, &:active, &:focus": {
                        opacity: 1,
                        backgroundColor: "transparent",
                    },
                    "&.Mui-selected": {  // Targeting the selected state
                        opacity: 1,
                        borderRadius: 0,
                        backgroundColor: "transparent",
                        borderBottom: "1px solid var(--error)",
                        borderWidth: "4px",
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            },
        },

        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: "var(--white)",
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "space-between"
                }
            }
        }, MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    color: "rgba(255, 255, 255, 0.48)",
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
            variants: [
                {
                    props: {color: "primary"},
                    style: {
                        padding: "1rem",
                        borderRadius: 16,
                        backgroundColor: "var(--border-disabled-BG)",
                        border: "1px solid var(--border-disabled)",
                        "@media (hover: hover)": {
                            "&:hover": {
                                backgroundColor: "var(--brand-3)", // Hover color only on hover-capable devices
                            },
                        },
                        "&.Mui-disabled": {
                            backgroundColor: "var(--brand-3)",
                            "@media (hover: hover)": {
                                "&:hover": {
                                    backgroundColor: "var(--brand-3)", // Disabled hover style
                                },
                            },
                        },
                    }
                },
            ],
            styleOverrides: {
                root: {
                    color: "#fff",
                    padding: "0.5rem",
                    minWidth: "2.5rem",
                    minHeight: "2.5rem",
                    borderRadius: 8,

                }

            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: 1.5,
                },
                h1: {
                    fontSize: 32,
                    lineHeight: "40px",
                },
                h2: {
                    fontSize: 24,
                    lineHeight: "32px",
                },
                h3: {
                    fontSize: 28,
                    lineHeight: "36px",
                },
                h4: {
                    fontSize: 18,
                    lineHeight: "26px",
                },
                h5: {
                    fontSize: 16,
                    lineHeight: "24px",
                    opacity: 0.5
                },
                subtitle1: {
                    fontSize: 40,
                    lineHeight: "48px",
                    "@media (max-width: 860px)": {
                        fontSize: 24,
                        lineHeight: "28px",
                        fontWeight: 400
                    },
                },
                subtitle2: {
                    fontSize: 20,
                    lineHeight: "28px",
                    color: "var(--grey-text)",
                    "@media (max-width: 860px)": {
                        fontSize: 16,
                        lineHeight: "24px",
                        fontWeight: 400
                    },
                },
                body1: {
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "24px",
                    color: "var(--white)",
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    color: "var(--white)",
                    backgroundColor: "var(--backgroundColor)",
                    fontSize: "18px",
                    lineHeight: "26px",
                    border: "1px solid var(--border-disabled)",
                    padding: "8px 16px",
                    borderRadius: "16px",
                    margin: "0 6px",
                    "&.Mui-selected": {
                        fontWeight: 800,
                        color: "var(--white)",
                        backgroundColor: "var(--brand-3)",
                    },
                    [breakpoints.down("md")]: {
                        fontSize: "18px",
                        minHeight: "fit-content",
                        minWidth: "fit-content",
                        lineHeight: "20px",
                        borderRadius: "4px", // Remove border for max-width < 860px
                    },
                    "@media (max-width: 860px)": {
                        fontSize: 14,
                        lineHeight: "20px",
                        fontWeight: 700
                    },
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    alignItems: "center",
                },
                indicator: {
                    display: "none"
                },
                flexContainer: {
                    display: "block"
                },
                scrollButtons: {
                    width: 50,
                    height: 50,
                    // backgroundColor: 'var(--bgColor-disabled)',
                    // border: '1px solid var(--border-disabled)',
                    // borderRadius: '16px'
                    variants: [
                        {
                            props: {indicatorColor: "secondary"},
                            style: {
                                padding: 4,
                                borderRadius: "16px",
                                border: " 1px solid var(--border)",
                                background: " var(--border-disabled-BG)",
                                backdropFilter: "blur(10px)"
                            }
                        }
                    ]
                }
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    height: 8,
                    borderRadius: "100px",
                },
                bar: {
                    backgroundColor: "var(--error)",
                    borderRadius: "100px", // Optional: to match the rounded corners of the root
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#141414A3",
                    opacity: ".6",
                    boxShadow: "none",
                    zIndex: 100,
                    borderRadius: "24px"
                }
            }
        },
        MuiTouchRipple: {
            styleOverrides: {
                root: {
                    display: "none"
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: "20px"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: "6px 12px",
                    borderRadius: "100px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    maxWidth: "fit-content",
                    display: "flex",
                    border: "none",
                    marginLeft: "0!important",
                    fontSize: "18px",
                    lineHeight: "26px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: ".3rem",
                    "@media (max-width: 860px)": {
                        fontSize: 12,
                        lineHeight: "18px"
                    },
                },
            },
            variants: [
                {
                    props: {color: "default"},
                    style: {
                        padding: "6px 12px",
                        borderRadius: "100px",
                        color: "var(--white)",
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        maxWidth: "fit-content",
                        display: "flex",
                        border: "none",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: ".3rem",

                    }
                },
                {
                    props: {color: "error"},
                    style: {
                        padding: "6px 12px",
                        borderRadius: "100px",
                        color: "var(--white)",
                        backgroundColor: "var(--brand-3)",
                        maxWidth: "fit-content",
                        fontWeight: "800",
                        display: "flex",
                        border: "none",
                        gap: ".3rem",
                    }
                }, {
                    props: {color: "secondary"},
                    style: {
                        padding: "6px 12px",
                        borderRadius: "100px",
                        color: "var(--white)",
                        border: "1px solid var(--border)",
                        backgroundColor: "rgba(255, 255, 255, 0.01)",
                        maxWidth: "fit-content",
                        display: "flex",
                        gap: ".3rem",
                    }
                }, {
                    props: {color: "primary"},
                    style: {
                        padding: "6px 12px",
                        borderRadius: "100px",
                        color: "var(--white)",
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                        maxWidth: "fit-content",
                        display: "flex",
                        border: "none",
                        gap: ".3rem",

                    }
                },
                {
                    props: {variant: "filled"},
                    style: {
                        padding: 0,
                        fontSize: "14px",
                        lineHeight: "20px",
                        width: "fit-content"
                    }
                }
                ,]
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#141414A3",
                    color: "var(--white)",
                    borderRadius: 0,
                    border: "1px solid var(--border)"
                },
                list: {
                    maxHeight: "350px",
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        },

        MuiMenuItem: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    padding: "18px 1rem",
                    "&.Mui-selected": {
                        backgroundColor: "var(--border-disabled-BG)",
                        "&:hover, &:active, &:focus": {
                            backgroundColor: "var(--border-disabled-BG)"
                        }
                    },
                    ":hover": {
                        backgroundColor: "var(--border-disabled-BG)"
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 60,
                    height: 32,
                    padding: 0,
                    display: "flex",

                    "& .MuiSwitch-switchBase": {
                        padding: 2,
                        "&.Mui-checked": {
                            transform: "translateX(28px)",
                            color: "#fff",
                            "& + .MuiSwitch-track": {
                                opacity: 1,
                                backgroundColor: "var(--error)"
                            }
                        }
                    },
                    "& .MuiSwitch-thumb": {
                        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
                        width: 28,
                        height: 28,
                        borderRadius: "50%"

                    },
                    "& .MuiSwitch-track": {
                        borderRadius: 50 / 2,
                        opacity: 1,
                        backgroundColor: "var(--disabled-text)",
                        boxSizing: "border-box"
                    }
                }
            }
        }
    },
});

export default muiTheme;
