import React from "react";

interface IProps {
    size?: number;
    height?: number;
}

export const Logo: React.FC<IProps> = ({size, height}) => {
    return (
        <svg width={size || 198} height={height || 68} viewBox="0 0 198 68" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.4997 4.60308V0H0V29.7108H4.60116V30.1292H4.60324V37.8708H4.60116V38.7077H0.000103759V68L29.4997 68V63.3969H38.4966V68L37.8709 68H68V37.8708H67.9974V38.7077H63.3943V29.7108H67.9974V30.1292H68V1.19723e-05L38.4966 0V4.60308H29.4997ZM18.4115 15.2739H49.796V24.3666H27.5042V29.8154H41.8446V38.7077H27.5042V43.7253H49.796V52.8181H18.4115V38.7077V29.8154V15.2739Z"
                  fill="#FF0402"/>
            <path
                d="M98.3972 53.7932C96.2457 53.7932 94.3774 53.3779 92.7921 52.5475C91.2068 51.6792 89.98 50.4335 89.1119 48.8103V65.2877H78.3545V23.4431H87.6964V27.9163C88.7533 26.2931 90.0555 25.0474 91.6031 24.1792C93.1884 23.3109 95.0945 22.8768 97.3215 22.8768C99.322 22.8768 101.153 23.2543 102.813 24.0093C104.474 24.7643 105.927 25.8401 107.173 27.2368C108.419 28.6335 109.381 30.2567 110.061 32.1064C110.74 33.9561 111.08 35.9946 111.08 38.2218C111.08 41.2039 110.532 43.8652 109.438 46.2057C108.343 48.5461 106.833 50.3958 104.908 51.7547C103.021 53.1137 100.851 53.7932 98.3972 53.7932ZM94.6604 44.7334C95.4531 44.7334 96.1703 44.5636 96.8119 44.2238C97.4913 43.8841 98.0764 43.4122 98.5671 42.8083C99.0578 42.2043 99.4352 41.5059 99.6994 40.7132C99.9637 39.9205 100.096 39.0711 100.096 38.1651C100.096 37.2214 99.9448 36.3721 99.6428 35.6171C99.3786 34.8621 98.9823 34.2204 98.4538 33.6919C97.9254 33.1257 97.3026 32.6915 96.5855 32.3896C95.8683 32.0876 95.0756 31.9366 94.2075 31.9366C93.7168 31.9366 93.2261 32.0121 92.7354 32.1631C92.2825 32.2763 91.8484 32.4651 91.4332 32.7293C91.018 32.9558 90.6028 33.2578 90.1876 33.6353C89.8102 34.0128 89.4516 34.4657 89.1119 34.9942V40.147C89.4516 41.0152 89.9045 41.8079 90.4707 42.5251C91.0746 43.2046 91.7352 43.752 92.4523 44.1672C93.1695 44.5447 93.9055 44.7334 94.6604 44.7334Z"
                fill="#FF0402"/>
            <path d="M113.88 53.227V23.4431H124.637V53.227H113.88ZM113.88 20.9516V11.8919H124.637V20.9516H113.88Z"
                  fill="#FF0402"/>
            <path
                d="M143.669 53.7932C141.026 53.7932 138.686 53.3779 136.648 52.5475C134.647 51.717 132.949 50.5845 131.552 49.1501C130.156 47.6779 129.099 46.0169 128.382 44.1672C127.665 42.3175 127.306 40.3734 127.306 38.335C127.306 35.6171 127.929 33.0879 129.174 30.7475C130.458 28.3693 132.307 26.463 134.723 25.0285C137.176 23.5941 140.158 22.8768 143.669 22.8768C147.254 22.8768 150.274 23.6318 152.727 25.1418C155.181 26.6517 157.011 28.5958 158.219 30.974L147.745 34.1449C147.254 33.4277 146.65 32.8803 145.933 32.5028C145.254 32.1253 144.48 31.9366 143.612 31.9366C142.631 31.9366 141.744 32.2008 140.951 32.7293C140.158 33.22 139.517 33.9373 139.026 34.881C138.573 35.8247 138.346 36.976 138.346 38.335C138.346 39.6562 138.573 40.8076 139.026 41.789C139.517 42.7328 140.158 43.4689 140.951 43.9973C141.744 44.4881 142.631 44.7334 143.612 44.7334C144.216 44.7334 144.763 44.658 145.254 44.507C145.745 44.3182 146.197 44.054 146.613 43.7142C147.066 43.3745 147.443 42.9781 147.745 42.5251L158.219 45.696C157.427 47.2815 156.332 48.6782 154.935 49.8862C153.577 51.0941 151.954 52.0567 150.066 52.774C148.217 53.4534 146.084 53.7932 143.669 53.7932Z"
                fill="#FF0402"/>
            <path
                d="M178.215 32.174C177.611 32.4295 176.961 32.6734 176.264 32.9057C175.59 33.1148 174.894 33.2774 174.174 33.3935C173.477 33.5097 172.791 33.5678 172.118 33.5678C171.119 33.5678 170.202 33.4052 169.365 33.0799C168.529 32.7315 167.856 32.174 167.345 31.4074C166.857 30.6175 166.613 29.5606 166.613 28.2365V19.8388H164.348V14.8559H166.613V9.10648H173.233V14.8559H176.821V19.8388H173.233V25.9018C173.233 26.5058 173.361 26.9356 173.616 27.1911C173.872 27.4234 174.22 27.5396 174.661 27.5396C174.986 27.5396 175.358 27.4815 175.776 27.3653C176.194 27.2492 176.612 27.1098 177.031 26.9472L178.215 32.174Z"
                fill="#FF0402"/>
            <path
                d="M184.621 33.1845L178.454 14.8559H185.248L188.523 27.2956L191.833 14.8559H198L191.833 33.1845H184.621Z"
                fill="#FF0402"/>
        </svg>
    );
};
