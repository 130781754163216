import {createAsyncThunk} from "@reduxjs/toolkit";
import {getContentDetails, getGenreContent, getPage, getRelatedContent, getSearchPage} from "../../services/contentAPI";

export const fetchGenreContent = createAsyncThunk(
    "content/fetchGenreContent",
    async (id: string | number) => {
        return await getGenreContent(id);
    }
);

export const fetchPage = createAsyncThunk(
    "content/fetchPage",
    async (page: string) => {
        return await getPage(page);
    }
);

export const fetchSearchPage = createAsyncThunk(
    "content/fetchSearchPage",
    async ({ query, page }: { query: string; page: number | string }) => {
        return await getSearchPage(query, page);
    }
);

export const fetchContentDetails = createAsyncThunk(
    "content/fetchContentDetails",
    async ({userId, id}: { userId: string | number; id: string | number }) => {
        return await getContentDetails(userId, id);
    }
);
export const fetchRelatedContent = createAsyncThunk(
    "content/fetchRelatedContent",
    async (id: string | number) => {
        return await getRelatedContent(id);
    }
);
// // Thunk to get All Content
// export const fetchAllContent = createAsyncThunk(
//     "content/fetchAllContent",
//     async () => {
//         const response = await getAllContent();
//         return response.data;
//     }
// );
//
// // Thunk to get Page Categories
// export const fetchPageCategories = createAsyncThunk(
//     "content/fetchPageCategories",
//     async () => {
//         const response = await getPageCategories();
//         return response.data;
//     }
// );
//
// // Thunk to get Content Details

