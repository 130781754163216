import React, {useState} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {Star, Play} from "../../assets/files";
import styles from "./FilmDescription.module.css";
import CustomChip from "../Chip/Chip";
import {Button} from "../index";
import {IData} from "../Cards/types";
import {useNavigate} from "react-router-dom";
import {routes} from "../../routes/routes";
import {AppDispatch} from "../../store";
import {useDispatch} from "react-redux";
import Keys from "../../constants/helper";
import {getContentDetails} from "../../services/contentAPI";
import {openVideoPlayer} from "../../store/player/playerSlice";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";
import clsx from "clsx";


interface IProps {
    data: IData | null;
    details?: boolean;
}

const FilmDescription: React.FC<IProps> = (props) => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const {data, details = false} = props;
    const navigate = useNavigate();
    const genreArray = data?.genders && data?.genders.split(",") || [];
    const profileId = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const dispatch: AppDispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false)
    const handlePlay = async () => {
        setIsLoader(true);
        if (profileId && data?.id) {
            try {
                const res = await getContentDetails(profileId, Number(data.id));
                if (res?.seasons?.length > 0) {
                    const firstSeason = res.seasons[0];
                    const firstEpisode = firstSeason.chapters?.[0];
                    if (firstEpisode?.video) {
                        // Dispatch to open the video player with the first episode
                        dispatch(openVideoPlayer({
                            id: Number(data?.id),
                            title: res.title || "",
                            type: res.type || "",
                            videoData: firstEpisode.video,  // Pass first episode video data
                            serialData: {
                                chapters: firstSeason.chapters,
                                seasonNumber: firstSeason.season_number,
                                episodeNumber: firstEpisode.chapter_number,
                                episodeTitle: firstEpisode.title || "Episode 1"
                            }
                        }));
                    }
                } else if (res?.video) {
                    // Fallback for a single video without seasons/episodes
                    dispatch(openVideoPlayer({
                        id: Number(data?.id),
                        title: res?.title || "",
                        type: res?.type || "",
                        videoData: res.video,
                    }));
                }
            } catch (error) {
                console.error("Error fetching content details:", error);
            } finally {
                setIsLoader(false);
            }
        } else {
            console.warn("Profile ID or data ID is missing.");
        }
    };

    return (
        <Box  className={clsx(styles.box, details && styles.box_details)}>
            <Typography variant={"h2"}>{data?.title}</Typography>
            <Stack direction="row" spacing={1} alignItems={"center"} gap={1}>
                {data?.rating && <span className={styles.rating}><Star/> {data?.rating}</span>}
                {data?.duration && <CustomChip label={data.duration} color="default"/>}
                {data?.classification && <CustomChip label={data.classification} color="primary"/>}
            </Stack>
            <Typography variant={"h5"}>{data?.synopsis}</Typography>
            <Stack direction="row" className={styles.chips}>
                {genreArray.map((genre: string) => (
                    <CustomChip key={genre} label={genre} color="default"/>
                ))}
            </Stack>
            {!details && (
                <Stack direction="row" spacing={2} mt={innerWidth > 860 ? 7 : 3} zIndex={1}
                       justifyContent={innerWidth < 860 ? "center" : "start"}>
                    <Button
                        isLoading={isLoader}
                        color="primary"
                        variant="contained"
                        startIcon={<Play/>}
                        onClick={handlePlay}
                    >
                        {t("Buttons.play")}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => navigate(`${routes.details.details}/${data?.id}`)}
                    >
                        {t("Buttons.details")}
                    </Button>
                </Stack>
            )}
        </Box>
    );
};

export default FilmDescription;
