import React from "react";
import {Typography} from "@mui/material";
import {Button} from "../index";
import styles from "./Informative.module.css"
import {useNavigate} from "react-router-dom";
import {routes} from "../../routes/routes";

interface IProps {
    title?: string;
    subtitle?: string;
}

const Informative: React.FC<IProps> = (props) => {
    const {title, subtitle} = props
    const navigate = useNavigate();
    const handleNavigate = () => navigate(routes.login)

    return (
        <div className={styles.root}>
            <Typography component="h2" variant="subtitle1">{title}</Typography>
            <Typography component="h4" variant={"subtitle2"}>{subtitle}</Typography>
            <Button fullWidth color="primary" variant="contained" onClick={handleNavigate}>Back to Log In</Button>
        </div>
    );
};

export default Informative;
