import clsx from "clsx";
import * as React from "react";
import styles from "./Loader.module.css"
import helper from "../../constants/helper";

interface LoaderProps {
    className?: string | any;
}

const Loader: React.FC<LoaderProps> = ({className}) => {
    const role = localStorage.getItem(helper.EPIC_USER_ROLE);

    return (
        <div className={clsx(styles.root, role === "KIDS" && styles.root_kids, className)}>
            <span className={styles.loader}/>
        </div>
    );
};

export default Loader
