import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import styles from "./styles.module.css";
import {getAvatars, updateProfile} from "../../../services/userAPI";
import Avatar from "../../../assets/images/Avatar.png"
import {Loader} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {fetchUserProfile} from "../../../store/profile/thunks";
import {setUser} from "../../../store/reducer";
import {useTranslation} from "react-i18next";

interface IAvatars {
    id: number;
    file: string | null;
}

interface IProps {
    id: number | string | null
}


const ProfileImage: React.FC<IProps> = (props) => {
    const {t} = useTranslation("translations");

    const {id} = props
    const dispatch: AppDispatch = useDispatch()
    const [data, setData] = useState<IAvatars[]>([]); // Initialize with an empty array
    const [loading, setLoading] = useState<boolean>(true)
    const {profile} = useSelector((state: RootState) => state.profile);


    useEffect(() => {
        id && dispatch(fetchUserProfile(id))
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAvatars();
                setData(res);
            } catch (error) {
                console.error("Error fetching avatars:", error);
            } finally {
                setLoading(false)
            }
        };
        fetchData?.();
    }, []);

    if (loading) return <Loader className={styles.loader}/>

    const handleChangeAvatar = async (key: number | string) => {
        if (id) {
            const res = await updateProfile(id, {avatar: key as string});
            if (res) {
                dispatch(fetchUserProfile(id));
                dispatch(setUser(res))
            }
        }
    }

    return (
        <div className={styles.content}>
            <Typography variant={"h2"} width={"100%"}>{t("Settings.category")}</Typography>
            <Box className={styles.avatars}>
                {data.map((e) => (
                    <img src={e.file || Avatar} alt={"avatar"} key={e.id} onClick={() => handleChangeAvatar(e.id)}
                         loading={"lazy"}
                         className={(profile && profile?.avatar?.id === e?.id) ? styles.active : ""}/>
                ))}
            </Box>
        </div>
    );
};

export default ProfileImage;
