export const UserDownIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3436_4122)">
            <path
                d="M13.3334 4.16675L15.8334 6.66675M15.8334 6.66675L18.3334 4.16675M15.8334 6.66675V1.66675M18.3334 10.0001V14.3334C18.3334 15.7335 18.3334 16.4336 18.0609 16.9684C17.8212 17.4388 17.4387 17.8212 16.9683 18.0609C16.4336 18.3334 15.7335 18.3334 14.3334 18.3334H5.66669C4.26656 18.3334 3.56649 18.3334 3.03171 18.0609C2.56131 17.8212 2.17885 17.4388 1.93917 16.9684C1.66669 16.4336 1.66669 15.7335 1.66669 14.3334V5.66675C1.66669 4.26662 1.66669 3.56655 1.93917 3.03177C2.17885 2.56137 2.56131 2.17892 3.03171 1.93923C3.56649 1.66675 4.26656 1.66675 5.66669 1.66675H10M1.78814 16.6053C2.17909 15.1989 3.46902 14.1667 5.00002 14.1667H10.8334C11.6078 14.1667 11.995 14.1667 12.317 14.2308C13.6393 14.4938 14.6729 15.5275 14.936 16.8498C15 17.1718 15 17.559 15 18.3334M11.6667 7.91675C11.6667 9.7577 10.1743 11.2501 8.33335 11.2501C6.4924 11.2501 5.00002 9.7577 5.00002 7.91675C5.00002 6.0758 6.4924 4.58341 8.33335 4.58341C10.1743 4.58341 11.6667 6.0758 11.6667 7.91675Z"
                stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_3436_4122">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export const UserEditIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.16669 12.5H6.66669C5.11355 12.5 4.33698 12.5 3.72441 12.7537C2.90765 13.092 2.25874 13.741 1.92042 14.5577C1.66669 15.1703 1.66669 15.9469 1.66669 17.5M12.9167 2.7423C14.1383 3.23679 15 4.43443 15 5.83333M9.99991 17.9167L11.6875 17.5792C11.8346 17.5497 11.9082 17.535 11.9768 17.5081C12.0377 17.4842 12.0956 17.4533 12.1492 17.4159C12.2097 17.3737 12.2627 17.3206 12.3688 17.2145L17.9167 11.6667C18.377 11.2065 18.377 10.4602 17.9167 9.99998C17.4565 9.53975 16.7103 9.53976 16.25 10L10.7021 15.5479C10.596 15.654 10.543 15.707 10.5008 15.7675C10.4634 15.8211 10.4324 15.879 10.4086 15.9399C10.3817 16.0085 10.3669 16.082 10.3375 16.2292L9.99991 17.9167ZM11.25 5.83333C11.25 7.67428 9.75764 9.16667 7.91669 9.16667C6.07574 9.16667 4.58335 7.67428 4.58335 5.83333C4.58335 3.99238 6.07574 2.5 7.91669 2.5C9.75764 2.5 11.25 3.99238 11.25 5.83333Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);
export const TranslateIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.16669 6.66675L8.33335 10.8334M3.33335 11.6667L8.33335 6.66675L10 4.16675M1.66669 4.16675H11.6667M5.83335 1.66675H6.66669M10.7609 14.1667H16.7392M10.7609 14.1667L9.16669 17.5001M10.7609 14.1667L13.1486 9.17427C13.341 8.77197 13.4372 8.57083 13.5688 8.50726C13.6833 8.45198 13.8167 8.45198 13.9312 8.50726C14.0628 8.57083 14.159 8.77197 14.3514 9.17427L16.7392 14.1667M16.7392 14.1667L18.3334 17.5001"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);
export const PasscodeIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.3334 9.16675V6.83342C18.3334 5.89999 18.3334 5.43328 18.1517 5.07676C17.9919 4.76316 17.7369 4.50819 17.4233 4.3484C17.0668 4.16675 16.6001 4.16675 15.6667 4.16675H4.33335C3.39993 4.16675 2.93322 4.16675 2.5767 4.3484C2.2631 4.50819 2.00813 4.76316 1.84834 5.07676C1.66669 5.43328 1.66669 5.89999 1.66669 6.83341V9.83342C1.66669 10.7668 1.66669 11.2335 1.84834 11.5901C2.00813 11.9037 2.2631 12.1586 2.5767 12.3184C2.93322 12.5001 3.39993 12.5001 4.33335 12.5001H9.16669M10 8.33341H10.0042M14.1667 8.33341H14.1709M5.83335 8.33341H5.83752M16.0417 14.1667V12.7084C16.0417 11.903 15.3888 11.2501 14.5834 11.2501C13.7779 11.2501 13.125 11.903 13.125 12.7084V14.1667M10.2084 8.33341C10.2084 8.44847 10.1151 8.54175 10 8.54175C9.88496 8.54175 9.79169 8.44847 9.79169 8.33341C9.79169 8.21836 9.88496 8.12508 10 8.12508C10.1151 8.12508 10.2084 8.21836 10.2084 8.33341ZM14.375 8.33341C14.375 8.44847 14.2817 8.54175 14.1667 8.54175C14.0516 8.54175 13.9584 8.44847 13.9584 8.33341C13.9584 8.21836 14.0516 8.12508 14.1667 8.12508C14.2817 8.12508 14.375 8.21836 14.375 8.33341ZM6.04169 8.33341C6.04169 8.44847 5.94841 8.54175 5.83335 8.54175C5.71829 8.54175 5.62502 8.44847 5.62502 8.33341C5.62502 8.21836 5.71829 8.12508 5.83335 8.12508C5.94841 8.12508 6.04169 8.21836 6.04169 8.33341ZM13 17.5001H16.1667C16.6334 17.5001 16.8668 17.5001 17.045 17.4093C17.2018 17.3294 17.3293 17.2019 17.4092 17.0451C17.5 16.8668 17.5 16.6335 17.5 16.1667V15.5001C17.5 15.0334 17.5 14.8 17.4092 14.6218C17.3293 14.465 17.2018 14.3375 17.045 14.2576C16.8668 14.1667 16.6334 14.1667 16.1667 14.1667H13C12.5333 14.1667 12.3 14.1667 12.1217 14.2576C11.9649 14.3375 11.8374 14.465 11.7575 14.6218C11.6667 14.8 11.6667 15.0334 11.6667 15.5001V16.1667C11.6667 16.6335 11.6667 16.8668 11.7575 17.0451C11.8374 17.2019 11.9649 17.3294 12.1217 17.4093C12.3 17.5001 12.5333 17.5001 13 17.5001Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


);
export const TrashIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
export const LogOutIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 14.1682L17.5 10.0016M17.5 10.0016L13.3333 5.83489M17.5 10.0016H7.5M10 14.1682C10 14.4145 10 14.5377 9.99085 14.6444C9.89569 15.7532 9.07905 16.6656 7.98753 16.8826C7.88252 16.9035 7.76001 16.9171 7.51529 16.9443L6.66412 17.0389C5.3854 17.181 4.74601 17.252 4.23805 17.0895C3.56078 16.8727 3.00785 16.3778 2.71765 15.7286C2.5 15.2417 2.5 14.5984 2.5 13.3118V6.6913C2.5 5.40469 2.5 4.76139 2.71765 4.27449C3.00785 3.62529 3.56078 3.13039 4.23805 2.91366C4.74601 2.75112 5.38538 2.82216 6.66412 2.96424L7.51529 3.05881C7.7601 3.08601 7.8825 3.09961 7.98753 3.1205C9.07905 3.33752 9.89569 4.24992 9.99085 5.35873C10 5.46542 10 5.58858 10 5.83489"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);
export const CheckIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6666 5L7.49992 14.1667L3.33325 10" stroke="white" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
);

