import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducer";
import categoriesSlice from "./categories/categoriesSlice";
import contentsSlice from "./contents/contentsSlice";
import userSlice from "./profile/profileSlice";
import videoPlayerReducer from "./player/playerSlice";
import activeTVSlice from "./activeTV/activeTVSlice";

const combine = combineReducers({
  user: authReducer,
  categories: categoriesSlice,
  contents: contentsSlice, // Add your contents slice to the store
  profile: userSlice, // Add your contents slice to the store
  videoPlayer: videoPlayerReducer,
  tvContent: activeTVSlice


});

const store = configureStore({ reducer: combine });
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;


export { store };
