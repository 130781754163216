interface ILanguageData {
    name: string
    key: string
}

export const languageData: Array<ILanguageData> = [
    {name: "English", key: "en"},
    {name: "Spanish", key: "es"},
    // {name: "Portuguese", key: "portuguese"},
    // {name: "Polish", key: "polish"},
    // {name: "German", key: "german"},
    // {name: "French", key: "french"},
    // {name: "Italian", key: "italian"},
    // {name: "Chinese", key: "chinese"},
];
