import React, {useEffect, useLayoutEffect, useState} from "react";
import styles from "./FilmLists.module.css";
import {CustomTabs, CardSlider, Loader} from "../index";
import {Stack, Typography} from "@mui/material";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchCategories} from "../../store/categories/categoriesSlice";
import {IContent} from "../Cards/types";
import {getGenreContent} from "../../services/contentAPI";
import {routes} from "../../routes/routes";
import {useNavigate} from "react-router-dom";
import {Arrow} from "../../assets/files";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

interface IProps {
    dataContent?: IContent[] | null;
    pageName: string;
    isProgram?: boolean;
    isCategory?: boolean;
}

const FilmLists: React.FC<IProps> = (props) => {
    const {dataContent, isProgram, isCategory, pageName} = props
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {list} = useSelector((state: RootState) => state.categories);
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>(0);
    const [data, setData] = useState<IContent[]>(dataContent || []);


    useLayoutEffect(() => {
        dispatch(fetchCategories({page: pageName, type: isCategory ? "category_list" : ""}));
    }, [pageName]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (selected === 0 && dataContent) {
                    setData(dataContent);
                } else {
                    const res = await getGenreContent(selected);
                    const formattedData = Array.isArray(res) ? res : [res];
                    setData(formattedData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selected, dataContent]);

    return (
        <div className={styles.root}>
            <div className={styles.box}>
                <CustomTabs list={list} setSelected={setSelected} setLoading={setLoading}/>
                {loading ? <Loader className={styles.loader}/> :
                    (data.map((e, i) => (
                            <div key={i}>
                                {e.contents.length ? <>
                                    <Stack className={styles.row} direction="row" spacing={1}>
                                        <Typography component="h3" variant="subtitle2">
                                            {e.name}
                                        </Typography>
                                        {innerWidth > 860 &&
                                          <Typography
                                            component="h4"
                                            variant="subtitle2"
                                            onClick={() => navigate(`${routes.homeCategories}/${e.id}`, {state: {isProgram}})}>
                                              {t("Buttons.view_more")}
                                            <Arrow/>
                                          </Typography>}
                                    </Stack>
                                    <CardSlider data={e.contents} isProgram={isProgram}/>
                                </> : null}
                            </div>
                        ))
                    )}
            </div>
        </div>
    );
};

export default FilmLists;
