import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchContentDetails, fetchGenreContent, fetchPage, fetchRelatedContent, fetchSearchPage} from "./thunks";
import {IContent, IData, IRelated} from "../../components/Cards/types";

interface ContentsState {
    isFetching: boolean;
    isFetched: boolean;
    isLoader: boolean;
    isLoaded: boolean;
    data: IContent | null;
    dataPage: IContent[] | null;
    content: IData | null;
    related: IRelated[] | null;
}

const initialState: ContentsState = {
    isFetching: false,
    isFetched: false,
    isLoader: false,
    isLoaded: false,
    data: null,
    dataPage: [],
    related: [],
    content: null,
};

const handlePending = (state: ContentsState) => {
    state.isFetching = true;
};
const handleGet = (state: ContentsState) => {
    state.isLoader = true;
    state.isFetching = true;
    state.isLoaded = false;

};
const handleRejected = (state: ContentsState) => {
    state.isLoader = false;
    state.isFetching = false;
    state.isLoaded = false;
};
const handleFulfilled = <T>(state: ContentsState, action: PayloadAction<T>, key: keyof ContentsState) => {
    state[key] = action.payload as any; // Using 'as any' to allow dynamic key assignment
    state.isFetching = false;
    state.isLoader = false;
    state.isFetched = true;
    state.isLoaded = true;
};
const contentsSlice = createSlice({
    name: "contents",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Genre Content
            .addCase(fetchGenreContent.pending, handlePending)
            .addCase(fetchGenreContent.fulfilled, (state, action) => handleFulfilled(state, action, "data"))
            .addCase(fetchGenreContent.rejected, handleRejected)
            // Fetch Page
            .addCase(fetchPage.pending, handlePending)
            .addCase(fetchPage.fulfilled, (state, action) => handleFulfilled(state, action, "dataPage"))
            .addCase(fetchPage.rejected, handleRejected)
            // Fetch Search Page
            .addCase(fetchSearchPage.pending, handlePending)
            .addCase(fetchSearchPage.fulfilled, (state, action) => handleFulfilled(state, action, "related"))
            .addCase(fetchSearchPage.rejected, handleRejected)
            // Fetch Content Details
            .addCase(fetchContentDetails.pending, handleGet)
            .addCase(fetchContentDetails.fulfilled, (state, action) => handleFulfilled(state, action, "content"))
            .addCase(fetchContentDetails.rejected, handleRejected)
            // Fetch Related Content
            .addCase(fetchRelatedContent.pending, handlePending)
            .addCase(fetchRelatedContent.fulfilled, (state, action) => handleFulfilled(state, action, "related"))
            .addCase(fetchRelatedContent.rejected, handleRejected);
    },
});

export default contentsSlice.reducer;
