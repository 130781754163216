import API from "./http";
import {ILogin, IProfile} from "./types";
import {routes} from "../routes/routes";
import Keys from "../constants/helper";


export const postLogin = async (body: ILogin) => API.post("/token/", body);


export const getAvatars = async () => API.get("/accounts/avatars/");

export const getProfiles = async () => API.get("/accounts/profiles");

export const getProfilesById = async (id: string | number | undefined) => API.get(`/accounts/profiles/${id}`);

export const getList = async (id: string | number | undefined) => API.get(`/accounts/mylist/${id}`);

export const addToList = async (id: string | number, body: { content: number }) => {
    try {
        return await API.put(`/accounts/mylist/${id}/`, body);
    } catch (error) {
        console.error("API error", error);
        throw error;
    }
};
export const getWatchingList = async (id: string | number | undefined) => API.get(`/accounts/watching/${id}`);


export const continueWatchingList = async (id: string | number, body: { content: number, minute: number | string }) => {
    try {
        const response = await API.put(`/accounts/watching/${id}/`, body);
        return response.data;
    } catch (error) {
        console.error("API error", error);
        throw error;
    }
};

export const updateProfile = async (id: string | number | undefined, body: IProfile) => API.put(`/accounts/profiles/${id}/`, body);

export const createProfile = async (body: IProfile) => API.post("/accounts/profiles/", body);


export const deleteProfile = async (id: string | number | undefined) => API.delete(`/accounts/profiles/${id}/`);

export const fetchRelatedContent = async (id: any, body: {
    password: string
}) => API.post(`/accounts/clients/${id}/adult_verification/`, body);

export const forceLogout = () => {
    const keysToRemove = [
        Keys.EPIC_JWT,
        Keys.EPIC_REFRESH_TOKEN,
        Keys.EPIC_USER_DATA,
        Keys.EPIC_PROFILE_ID,
        Keys.EPIC_USER_ROLE
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
    window.location.href = routes.login
};
