import React, {useState} from "react";
import styles from "./CardContinueWatching.module.css";
import {
    Card,
    CardContent,
    CardMedia,
    Divider,
    IconButton,
    LinearProgress,
    Popover, Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {Checked, FullScreen, Plus} from "../../../assets/files";
import {IData, IRelated} from "../types";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../routes/routes";
import {IProfileList} from "../../../store/profile/profileSlice";
import clsx from "clsx";


interface IProps {
    data?: IData | IRelated | IProfileList;
}

const CardContinueWatching: React.FC<IProps> = ({data}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const navigate = useNavigate()
    const [isImageLoading, setImageLoading] = useState(true);
    const open = Boolean(anchorEl);
    const handleImageLoad = () => setImageLoading(false);


    const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl === event.currentTarget) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleNavigate = (id: number | string | undefined) => {
        navigate(`${routes.details.details}/${id}`)
    }
    return (
        <>
            <div className={styles.card} onClick={handleCardClick}>
                {isImageLoading && <Skeleton variant="rectangular" animation="wave" className={styles.poster}/>}
                <img src={data?.backdrop_path} onLoad={handleImageLoad}  alt={"MovieItem"}
                     className={clsx(styles.poster, isImageLoading ? styles.hidden : "")}/>
                {!isImageLoading &&
                  <div className={styles.progress_wrapper}>
                    <LinearProgress variant="determinate" value={25} className={styles.progress}/>
                  </div>}
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Card sx={{maxWidth: 430, minWidth: 350}}>
                        <span className={styles.type}>{data?.type}</span>
                        <CardMedia onClick={() => handleNavigate(data?.id)}
                                   sx={{height: 240, cursor: "pointer"}}
                                   image={data?.backdrop_path}
                                   title="Movie Background"
                        />
                        <CardContent className={styles.content}>
                            <Typography gutterBottom variant="h5" component="h5">
                                {data?.title}
                            </Typography>

                            <Divider/>
                            {/*{data?.}*/}
                            <Stack direction="column" mt={2} gap={2}>
                                <Typography>1 hour and 2 minutes left</Typography>
                                <div className={styles.progress_wrapper}>
                                    <LinearProgress variant="determinate" value={25} className={styles.progress}/>
                                </div>
                            </Stack>
                            <Stack direction="row" spacing={1} className={styles.controls}>
                                <div className={styles.controlsLeft}>
                                    <IconButton><Plus/></IconButton>
                                    <IconButton><Checked/></IconButton>
                                </div>
                                <div className={styles.controlsRight}>
                                    <IconButton><FullScreen/></IconButton>
                                </div>
                            </Stack>
                        </CardContent>
                    </Card>
                </Popover>
            </div>
        </>
    );
};

export default CardContinueWatching;
