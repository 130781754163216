import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {routes} from "./routes";
import {Box} from "@mui/material";
import {Loader, VideoPlayer} from "../components";
import PrivateOutlet from "../pages/PrivateOutlet";
import PublicOutlet from "../pages/PublicOutlet";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import Home from "../pages/Home";
import Movies from "../pages/Movies";
import Serials from "../pages/Serials";
import Adults from "../pages/Adults";
import Sports from "../pages/Sports";
import Settings from "../pages/Settings";
import Search from "../pages/Search";
import MyEpic from "../pages/MyEpic";
import LiveTv from "../pages/LiveTV";
import PageOutlet from "../pages/PageOutlet";

const Login = lazy(() => import("../pages/Login"));
const EmptyScreen = lazy(() => import("../models/EmptyScreen"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Profile = lazy(() => import("../pages/Profile"));
const ProfileEdit = lazy(() => import("../pages/ProfileEdit"));
const ProfileCreate = lazy(() => import("../pages/Profile/ProfileCreate"));
const HomeCategories = lazy(() => import("../models/HomeCategories"));
const DetailsPage = lazy(() => import("../models/DetailsPage"));
const AudioSetup = lazy(() => import("../models/DetailsPage/AudioSetup"));
const SerialsDetails = lazy(() => import("../models/SerialsDetails"));
const DetailsRecommendation = lazy(() => import("../models/DetailsPage/DetailsRecommendation/DetailsRecommendation"));


const MainRouter = () => {
    const {isVideoPlayerOpen} = useSelector((state: RootState) => state.videoPlayer);
    return (
        <BrowserRouter>
            <Suspense
                fallback={
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)"
                    }}>
                        <Loader/>
                    </Box>
                }
            >
                <Routes>
                    <Route path={routes.notFound} element={<EmptyScreen/>}/>

                    {/* Public Routes */}
                    <Route path="/" element={<PublicOutlet/>}>
                        <Route path={routes.login} element={<Login/>}/>
                        <Route path={routes.forgotPassword} element={<ForgotPassword/>}/>
                    </Route>

                    {/* Private Routes */}
                    <Route path="/" element={<PrivateOutlet/>}>
                        <Route path={routes.profile} element={<Profile/>}/>
                        <Route path={routes.profileCreate} element={<ProfileCreate/>}/>
                        <Route path={routes.profileEdit} element={<ProfileEdit/>}/>
                        <Route path={`${routes.profileEdit}/:id`} element={<ProfileEdit/>}/>
                        <Route path="/" element={<PageOutlet/>}>
                            <Route path={routes.home} element={<Home/>}/>
                            <Route path={routes.movies} element={<Movies/>}/>
                            <Route path={routes.serials} element={<Serials/>}/>
                            <Route path={routes.adults} element={<Adults/>}/>
                            <Route path={routes.sports} element={<Sports/>}/>
                            <Route path={routes.settings} element={<Settings/>}/>
                            <Route path={routes.search} element={<Search/>}/>
                            <Route path={routes.live} element={<LiveTv/>}/>
                            <Route path={routes.list} element={<MyEpic/>}/>
                        </Route>
                        <Route path={`${routes.homeCategories}/:id`} element={<HomeCategories/>}/>
                        <Route path={`${routes.details.details}/:id`} element={<DetailsPage/>}/>
                        <Route path={`${routes.details.details_setup}/:id`} element={<AudioSetup/>}/>
                        <Route path={`${routes.details.details_serials}/:id`} element={<SerialsDetails/>}/>
                        <Route path={`${routes.detailsRecommendation}/:id`} element={<DetailsRecommendation/>}/>
                    </Route>
                </Routes>
                {isVideoPlayerOpen && <VideoPlayer/>}
            </Suspense>
        </BrowserRouter>
    );
};

export default MainRouter;
