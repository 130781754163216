import React, {useState} from "react";
import {FormControl, MenuItem, Select, Typography, ListItemIcon} from "@mui/material";
import {CheckIcon, Chevron} from "../../assets/files";
import {capitalizeFirstLetter} from "../../constants/formats";

interface IOptions {
    value: string;
}

interface IProps {
    label?: string;
    value: string | number;
    options: Array<IOptions>;
}

const CustomSelect: React.FC<IProps> = (props) => {
    const {label, value, options} = props;
    const [open, setOpen] = useState(false);

    return (
        <FormControl fullWidth>
            <Typography variant={"h2"} mb={2}>{label}</Typography>
            <Select
                value={value}
                IconComponent={() => (
                    <Chevron rotate={open ? 180 : 0}/>
                )}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderValue={(selected) => capitalizeFirstLetter(String(selected))}
            >
                {options.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                        <ListItemIcon>
                            {value === e.value && <CheckIcon/>}
                        </ListItemIcon>
                        {capitalizeFirstLetter(e.value)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;
