import clsx from "clsx";
import {Button as MuiButton, ButtonProps as MuiButtonProps} from "@mui/material";
import React, {forwardRef, ReactNode} from "react";
import styles from "./Button.module.css";

interface ButtonProps extends MuiButtonProps {
    className?: string;
    fullWidth?: boolean;
    startIcon?: ReactNode,
    isLoading?: boolean;
    children: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((
    {
        className,
        isLoading,
        fullWidth,
        startIcon,
        children,
        ...restAttributes
    }, ref) => {

    const classes = clsx(
        className,
    );

    return (
        <MuiButton
            fullWidth={fullWidth}
            ref={ref}
            startIcon={!isLoading && startIcon}
            className={classes}
            {...restAttributes}
            disabled={isLoading || restAttributes.disabled}
        >
            {isLoading ? <div className={styles.loader}></div> : children}
        </MuiButton>
    );
});

export default Button;
