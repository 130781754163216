import React from "react";
import {Chip as ChipMui, ChipProps} from "@mui/material";

interface IProps extends ChipProps {
    label: string,
    color?: "default" | "error" | "secondary" | "primary",
    variant?: "outlined" | "filled"
}

const CustomChip: React.FC<IProps> = (props) => {
    const {label, color = "default", variant = "outlined"} = props;
    return <ChipMui label={label} color={color} variant={variant} />;
};

export default CustomChip;
