import React from "react";
import styles from "./EpisodeCard.module.css"
import {Typography} from "@mui/material";
import {ISeasonsChapters} from "../types";
import clsx from "clsx";

interface IProps {
    data: ISeasonsChapters,
    seasonNumber?: number | string,
    activeNumber?: number | string,
    onEpisodeChange: (seasonNumber: number, episodeNumber: number) => void; // Add this prop for episode change


}

const EpisodeCard: React.FC<IProps> = ({data, onEpisodeChange, seasonNumber, activeNumber}) => {

    const handleEpisodeClick = () => {
        onEpisodeChange(seasonNumber as number, data?.chapter_number); // Trigger the callback with season and episode numbers
    };
    const checkActiveEpisode = data?.chapter_number === activeNumber
    return (
        <div className={styles.wrapper}>
            <div className={styles.card} onClick={handleEpisodeClick}>
                <div>
                    <img src={data?.poster} alt={"MovieItem"} loading={"lazy"}
                         className={clsx(styles.poster, checkActiveEpisode && styles.poster_active)}/>
                </div>
                <div className={styles.progress_wrapper}>
                    <Typography variant={"h2"} className={styles.episode_count}>Episode {data?.chapter_number}</Typography>
                    <Typography variant={"h2"} className={styles.episode_title}>{data?.title}</Typography>
                    <Typography variant={"h4"}>{data?.duration}</Typography>
                </div>
            </div>
        </div>
    );
};

export default EpisodeCard;
